import gql from "graphql-tag";

export default gql`
  query {
    getUsers {
      _id
      email
      businessID
    }
  }
`;
