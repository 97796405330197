import React from 'react';
import styled from '@emotion/styled';
import parseJSON from '../../helpers/parseJSON';
import { AdminButtonBasic } from './AdminButtons';
import Group from './AdminGroup';

export const ForcedMaintenanceWrapper = styled('div')`
  display: flex;

  > div {
    &:first-of-type {
      margin-right: ${({theme}) => theme.space[4]};
    }
  }
`

const ForcedMaintenance = ({ forcedMaintenance, substances }) => {
	const maintenanceValues = parseJSON(forcedMaintenance.values);

	if (!maintenanceValues.substance || !maintenanceValues.amount) return null;

	const substance = substances.find(substance => substance._id === maintenanceValues.substance);

	if (!substance) return null;

	return (
		<>
			<div>{forcedMaintenance.date} - {forcedMaintenance.time}</div>
			<div>{substance.name}: {maintenanceValues.amount} kg</div>
		</>
	)
}

const ForcedMaintenances = ({ bath, substances, updateBath, refetchBathView }) => {
	const forcedMaintenance = parseJSON(bath.forcedMaintenance);

	const removeForcedMaintenenace = async (e) => {
		e.preventDefault();

		await updateBath({
			variables: { _id: bath._id, forcedMaintenance: 'delete' },
			refetchQueries: refetchBathView,
		});
	}

	return (
		<div>
			{forcedMaintenance && (
				<>
					<h3>Pågående tvingant underhåll</h3>
					<Group>
						<ForcedMaintenance forcedMaintenance={forcedMaintenance} substances={substances} />
					</Group>
					<AdminButtonBasic onClick={removeForcedMaintenenace}>Ta bort tvingande underhåll</AdminButtonBasic>
				</>
			)}
		</div>
	)
}

export default ForcedMaintenances