import gql from "graphql-tag";

export default gql`
  mutation updateBath($_id: String!, $name: String, $bathCategoryID: String, $location: String, $volume: String, $factor: String, $idealValue: String, $measurementInputs: String, $maintenanceSubstances: String, $interval: String, $forcedMaintenance: String) {
  	updateBath(_id: $_id, name: $name, bathCategoryID: $bathCategoryID, location: $location, volume: $volume, factor: $factor, idealValue: $idealValue, measurementInputs: $measurementInputs, maintenanceSubstances: $maintenanceSubstances, interval: $interval, forcedMaintenance: $forcedMaintenance) {
  		_id
      name
      bathCategoryID
      location
      volume
      factor
      idealValue
      measurementInputs
      maintenanceSubstances
      interval
      forcedMaintenance
  	}
  }
`;
