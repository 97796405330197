import gql from "graphql-tag";

export default gql`
  query($_id: String!, $limit: Int, $offset: Int, $type: String) {
    getBathReports(bathID: $_id, limit: $limit, offset: $offset, type: $type) {
      maintenance {
        _id
        date
        time
        values
      }
      measurement {
        _id
        date
        time
        status
        values
      }
      total
    }
  }
`;
