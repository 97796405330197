import React, { memo } from "react";
import { NavLink } from 'react-router-dom';
import styled from '@emotion/styled';
import Logout from '../Blocks/Logout';
import { ReactComponent as IconUser } from '../../svg/IconUser.svg';
import { ReactComponent as IconBusiness } from '../../svg/IconBusiness.svg';
import { ReactComponent as IconMeasurement } from '../../svg/IconMeasurement.svg';
import { ReactComponent as IconReport } from '../../svg/IconReport.svg';
import { ReactComponent as IconSubstance } from '../../svg/IconSubstance.svg';
import { ReactComponent as IconCog } from '../../svg/IconCog.svg';

const SidebarDiv = styled('div')`
  flex: 0 0 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${({theme}) => theme.colors.blueDark};
  color: #fff;

  svg {
    width: 1.25rem;
    height: 1.25rem;
    fill: #fff;
    stroke: #fff;
    margin-right: 1.275rem;
  }
`;

const ListItem = styled('li')`
  border-bottom: 1px solid #fff;
`;

const SidebarLink = styled(NavLink)`
  display: flex;
  align-items: center;
  padding: .875rem ${({theme}) => theme.space[4]};

  &.active, &:hover {
    background: ${({theme}) => theme.colors.blueDarkDarken};
  }
`;

export default memo(() => {
  return (
    <SidebarDiv>
      <ul>
        <ListItem><SidebarLink to={'/admin/users'}><IconUser />Användare</SidebarLink></ListItem>
        <ListItem><SidebarLink to={'/admin/businesses'}><IconBusiness />Företag</SidebarLink></ListItem>
        <ListItem><SidebarLink to={'/admin/measurements'}><IconMeasurement />Mätvärden</SidebarLink></ListItem>
        <ListItem><SidebarLink to={'/admin/substances'}><IconSubstance />Ämnen</SidebarLink></ListItem>
        <ListItem><SidebarLink to={'/admin/reports'}><IconReport />Rapporter</SidebarLink></ListItem>
        <ListItem><SidebarLink to={'/admin/settings'}><IconCog />Inställningar</SidebarLink></ListItem>
      </ul>
      <Logout />
    </SidebarDiv>
  )
});
