import React, { createContext, memo } from 'react';

const AppContext = createContext();

const AppContextProvider = memo((props) => {
  const appState = props;

  return <AppContext.Provider value={{ appState }}>{props.children}</AppContext.Provider>;
});

export { AppContext, AppContextProvider };
