import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import GET_BUSINESSES from '../../graphql/queries/GET_BUSINESSES';
import ReportsBathSelector from '../../components/Admin/ReportsBathSelector';
import Breadcrumb from '../../components/Admin/AdminBreadcrumb';
import { AdminButtonUpdate } from '../../components/Admin/AdminButtons';
import BathMeasurements from '../../components/Admin/BathMeasurements';
import Group from '../../components/Admin/AdminGroup';


const Reports = ({ match }) => {
  const [initialState, setInitialState] = useState({ reportType: 'maintenance' });
  const { data: { getBusinesses: businesses } = {}, loading, error } = useQuery(GET_BUSINESSES);

  if (!businesses) return '';

  const handleChange = e => {
    const inputTarget = e.target;
    const inputName = e.target.name;
    let inputValue = e.target.value;

    setInitialState(is => {
      is[inputName] = inputValue;
      return {
        ...is,
      }
    })
  };

  return (
    <>
      <Breadcrumb />
      <h1>Rapporter</h1>
      <Group>
        <label htmlFor="businessID">Välj företag</label>
        <p>
          <select id="businessID" name="businessID" onChange={handleChange}>
            <option value="">Inget valt</option>
            {businesses && businesses.length > 0 && businesses.map(b => <option key={b._id} value={b._id}>{b.name}</option>)}
          </select>
        </p>
        {initialState.businessID && <ReportsBathSelector businessID={initialState.businessID} handleChange={handleChange} />}
      </Group>
      {initialState.bathID && <BathMeasurements bathID={initialState.bathID} />}
    </>
  )
};

export default Reports;
