import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import UPDATE_REPORT_MEASUREMENT from '../../graphql/mutations/UPDATE_REPORT_MEASUREMENT';
import UPDATE_REPORT_MAINTENANCE from '../../graphql/mutations/UPDATE_REPORT_MAINTENANCE';
import { AdminDataRowMaintenance, AdminDataRowMeasurement, EditableInput } from '../ViewUser/ReportDataView'
import parseJSON from '../../helpers/parseJSON';
import { ReactComponent as IconOk } from '../../svg/IconOk.svg';
import { ReactComponent as IconAlert } from '../../svg/IconAlert.svg';
import isValidDateFormat from '../../helpers/isValidFormatDate';
import isValidTimeFormat from '../../helpers/isValidFormatTime';

// TODO - replace with helper function
const tryParseJSON = (bathReportString) => {
	let bathReportData = null;
	
	try {
		bathReportData = JSON.parse(bathReportString);
	} catch (error) {
		console.log('Error parsing json', error);
		
		return null;
	}

	return bathReportData;
}

const AdminReportEditor = ({ type, bath, substances, bathReport, exitEditMode, ReportValues, refetchBathReports }) => {
	const updateReport = async (e, type, report, updateReportMeasurement) => {
    e.preventDefault();
    if (!report && !report.id) return;

    if (!isValidDateFormat(report.date)) return false;
    if (!isValidTimeFormat(report.time)) return false;

    // const bathMeasurementData = tryParseJSON(bath.measurementInputs);

		let reportValues = tryParseJSON(report.values);

		if (reportValues && reportValues.length > 0) {
			reportValues = reportValues.filter(reportValue => {
				const value = reportValue.value;
				
				value.replace(',', '.');

				if (value !== 0 && !isNaN(Number(value))) {
					return reportValue;
				}
			});
		}
    
    let bathMeasurementData = null;
    bathMeasurementData = parseJSON(bath.measurementInputs)

    const values = [];
    let reportStatus = 'ok';
		let updatedReport = false;

		if (type === 'measurement') {
			for (let i = 0; i < reportValues.length; i++) {
				const formInput = reportValues[i];
				let inputValue = formInput.value;
				let status = 'ok';
				
				if (inputValue) {
					const valueId = formInput.id

					if (valueId) {
						if (bathMeasurementData[valueId] && bathMeasurementData[valueId].inputMin && bathMeasurementData[valueId].inputMax) {  
							const inputMin = Number(bathMeasurementData[valueId].inputMin);
							const inputMax = Number(bathMeasurementData[valueId].inputMax);
		
							if (inputValue < inputMin || inputValue > inputMax) {
								if (inputValue < inputMin) {
									status = 'error';
									reportStatus = bathMeasurementData[valueId].substance ? 'errorCritical' : 'error';              
								} else if (inputValue > inputMax) {
									status = 'ok'
								}
							} else {
								status = 'ok';
							}
						}
						
						values.push({id: valueId, value: inputValue, status })
					}
				}
			}

			const updatedReportRes = await updateReportMeasurement({
				variables: {
					_id: report._id,
					bathID: bath._id,
					date: report.date,
					time: report.time,
					status: reportStatus,
					values: JSON.stringify(values)
				}
			})

			updatedReport = updatedReportRes && updatedReportRes.data;
		} else {
			const formInputsTotal = e.target.length - 1;

			for (let i = 0; i < formInputsTotal; i++) {
				const formInput = e.target[i];
				const inputId = formInput.getAttribute('data-id');
				let value = formInput.value;
	
				if (inputId === 'substance' || inputId === 'amount') {
					values.push({ value, status: 'ok' })
				}
			}

			if (!values[1] && !values[1].value || isNaN(values[1].value)) {
				return;
			}

			const updatedReportRes = await updateReportMaintenance({
				variables: {
					_id: report._id,
					bathID: bath._id,
					date: report.date,
					time: report.time,
					values: JSON.stringify(values)
				}
			})

			updatedReport = updatedReportRes && updatedReportRes.data;
		}

		if (updatedReport) {
			exitEditMode();
			
			await refetchBathReports();
		}
  }

	const handleInputChange = (e) => {
		const inputElement = e.target;
		const value = inputElement.value;
		const id = inputElement.getAttribute('data-id');
		let reportValues = tryParseJSON(editableReport.values);
		
		const maybeReportValueIndex = reportValues.findIndex(reportValue => reportValue.id === id);

		if (maybeReportValueIndex !== -1) {
			reportValues[maybeReportValueIndex].value = value;
		} else {
			reportValues.push({ id, value, status: 'ok' })
		}

		if (value) {
			setEditableReport(report => {
				if (id === 'date' || id === 'time') {
					report[id] = value;
				} else {
					report.values = JSON.stringify(reportValues);
				}
				
				return { ...report }
			})
		}	
	}

	const [updateReportMeasurement] = useMutation(UPDATE_REPORT_MEASUREMENT);
	const [updateReportMaintenance] = useMutation(UPDATE_REPORT_MAINTENANCE);
	const [editableReport, setEditableReport] = React.useState({ ...bathReport});

  const RowType = type === 'measurement' ? AdminDataRowMeasurement : AdminDataRowMaintenance;
	const maintenanceSubstances = parseJSON(bath.maintenanceSubstances)
  const maybeSubstancesOptions = Array.isArray(maintenanceSubstances) && substances.filter(substance => maintenanceSubstances.indexOf(substance._id) !== -1);

	const maybeEditableMaintenanceValues = parseJSON(editableReport.values);
	console.log('editableReport', editableReport);

	return (
		<form onSubmit={(e) => updateReport(e, type, editableReport, updateReportMeasurement, setEditableReport)} key={`edit-${editableReport._id}`}>
			<RowType>
				<span><EditableInput type="text" data-id="date" value={editableReport.date} onChange={handleInputChange} /></span>
				<span><EditableInput type="text" data-id="time" value={editableReport.time} onChange={handleInputChange} /></span>

				{type === 'maintenance' && (
					<>
						<span>
							{maybeSubstancesOptions.length > 0 
								? <select
									style={{maxWidth: '100px'}}
									type="text" 
									data-id={'substance'}
									required
									defaultValue={maybeEditableMaintenanceValues && maybeEditableMaintenanceValues[0] && maybeEditableMaintenanceValues[0].value}
								>
									{maybeSubstancesOptions.map((substance, i) => <option key={i} value={substance.name}>{substance.name}</option>)}
								</select>
								: 'Inga ämnen tillgängliga'}                  
						</span>
						<span>
							<EditableInput
								type="text" 
								data-id={'amount'}
								defaultValue={maybeEditableMaintenanceValues && maybeEditableMaintenanceValues[1] && maybeEditableMaintenanceValues[1].value}
								onChange={handleInputChange}
								required
							/>
							{/* <ReportValues reportValues={[maybeEditableMaintenanceValues[1]]} admin={true} editMode onChange={handleInputChange} /> */}
						</span>
					</>
				)}

				{type === 'measurement' && (
					<ReportValues reportValues={parseJSON(editableReport.values)} admin={true} editMode onChange={handleInputChange} />
				)}

				<span className='status'>
					{editableReport.status === 'ok' || editableReport.status === undefined ? <IconOk /> : <IconAlert /> }
				</span>
				<span className='save right'><button className="button" onClick={exitEditMode}>Avbryt</button></span>
			  <span className='save right'><button className="button" type="submit">Spara</button></span>
			</RowType>			
		</form>
	)
}

export default AdminReportEditor