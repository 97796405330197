import React from 'react';
import AdminReportsLoader from './AdminReportsLoader';


export default ({ bathID, getAdminBathView }) => {
  if (!bathID) return null;

  return (
    <>
      <h2>Mätrapporter</h2>
      <AdminReportsLoader getAdminBathView={getAdminBathView} bathID={bathID} type="maintenance" />
      <AdminReportsLoader getAdminBathView={getAdminBathView} bathID={bathID} type="measurement" />
    </>
  );
}
