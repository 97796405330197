import gql from "graphql-tag";

export default gql`
  query($businessID: String!) {
    getBathFromBusiness(businessID: $businessID) {
      _id
      name
    }
  }
`;
