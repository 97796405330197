import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';
import GET_BUSINESSES from '../../graphql/queries/GET_BUSINESSES';
import ADD_BUSINESS from '../../graphql/mutations/ADD_BUSINESS';
import { AdminButtonAdd } from '../../components/Admin/AdminButtons';
import Breadcrumb from '../../components/Admin/AdminBreadcrumb';
import Ul from '../../components/Admin/AdminList';
import slugifyURL from '../../helpers/slugifyURL';


const Businesses = () => {
  const { data: { getBusinesses } = {}, loading, error } = useQuery(GET_BUSINESSES);
  const refetchBusinesses = () => [{ query: GET_BUSINESSES, fetchPolicy: "network-only" }];
  const [addBusiness] = useMutation(ADD_BUSINESS);
  const [name, setName] = useState("");

  if (loading) return 'Loading'
  if (error) return 'Error'

  return (
    <>
      <Breadcrumb />
      <h1>Företag</h1>
      {getBusinesses && getBusinesses.length > 0 && (
        <Ul>
          {getBusinesses.map(b => <li key={b._id}><Link to={`/admin/businesses/${slugifyURL(b.name)}`}>{b.name}</Link></li>)}
        </Ul>
      )}
      <div>
      <form
        onSubmit={async e => {
          e.preventDefault();
          if (name) {
            const slug = slugifyURL(name);
            const response = await addBusiness({
              variables: {
                name,
                slug
              },
              refetchQueries: refetchBusinesses,
            });

          }
        }}
      >
        <div>
          <input
            type="text"
            value={name}
            placeholder="Företagsnamn"
            onChange={e => {
              setName(e.target.value);
            }}
          />
        </div>
        <AdminButtonAdd type="submit">Lägg till företag</AdminButtonAdd>
      </form>
      </div>
    </>
  );
};

export default Businesses;
