import React, { useState, useEffect, useContext } from 'react';
import RoutesAdmin from './RoutesAdmin';
import RoutesUser from './RoutesUser';
import AppLogin from './AppLogin';
import { UserRoleContext } from './UserRoleContext';
import { setAccessToken } from './handleToken';
import config from './appConfig';

export const AppStartup = () => {
  const { userRole, setUserRole } = useContext(UserRoleContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`${config.api}/refresh_token`, {
      method: "POST",
      credentials: "include"
    }).then(async x => {
      const { accessToken, role } = await x.json();

      setAccessToken(accessToken)
      setUserRole(role);
      setLoading(false);

    });
  }, []);

  if (loading) return <div>loading...</div>

  switch (userRole) {
    case 'user':
    return <RoutesUser />

    case 'admin':
    return <RoutesAdmin />

    default: return <AppLogin />
  }
};
