import React from 'react';
import styled from '@emotion/styled';
import { Link, useLocation } from 'react-router-dom';

const adminStrings = {
  'business': 'Företag',
  'businesses': 'Företag',
  'user': 'Användare',
  'users': 'Användare',
  'measurements': 'Mätvärden',
  'reports': 'Rapporter',
  'settings': 'Inställningar',
  'substances': 'Ämnen',
}

const BreadcrumbWrapper = styled('div')`
  margin-bottom: 1rem;
`;

const Breadcrumb = ({ last, secondLast, thirdLast, fourthLast }) => {
  const { pathname } = useLocation();
  const splitPaths = pathname.split('/');
  const paths = splitPaths.filter(el => (el));
  let pathString = '';
  const pathCount = paths.length - 1;

  return (
    <BreadcrumbWrapper>
    {paths.length > 0 && paths.map((p, i) => {
      let linkName;
      const path = `/${p}`;

      switch (i) {
        case 0:
          linkName = last ? last : p;
        break;
        case 1:
          linkName = secondLast ? secondLast : p;
        break;
        case 2:
          linkName = thirdLast ? thirdLast : p;
        break;
        case 3:
          linkName = fourthLast ? fourthLast : p;
        break;
        default: linkName = p;
      }

      if (linkName === 'admin') {
        linkName = 'Admin';
      }

      if (adminStrings[p]) {
        linkName = adminStrings[p];
      }

      pathString += path;

      return <Link key={path} to={pathString}>{linkName}{i !== pathCount && (<span> / </span>)}</Link>
    })}
    </BreadcrumbWrapper>
  );
}

export default Breadcrumb;
