import gql from "graphql-tag";

export default gql`
query {
  getAppSettings {
    information {
      customerServicePhone
      maintenanceDescription
    }
    measurementInputs {
      _id
      name
      adminName
      description
      errorMessage
      order
      icon
    }
    substances {
      _id
      name
    }
  }
}
`;
