import React, { useState, useEffect, useContext } from "react";
import { Redirect, useHistory } from "react-router-dom";
import styled from "@emotion/styled";
import { useMutation } from "@apollo/react-hooks";
import { setAccessToken } from "../handleToken";
// import GET_ME from "../graphql/queries/GET_ME";
import USER_LOGIN from "../graphql/mutations/USER_LOGIN";
import HeaderBar from "../components/Blocks/HeaderBar";
import LinkItem from "../components/Elements/LinkItem";
// import Button from "../components/Elements/Button";
import { UserRoleContext } from "../UserRoleContext";

const FormContainer = styled("div")`
  position: relative;
  height: 100%;
`;

const StyledForm = styled("form")`
  width: 100%;
  max-width: 486px;
  margin: auto;
  text-align: center;
  padding: 0 ${({ theme }) => theme.space[2]};
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  padding-top: ${({ theme }) => theme.space[6]};

  ${({ theme }) => theme.mediaQuery.md} {
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -25%);
  } ;
`;

const StyledInput = styled("input")`
  width: calc(100% - ${({ theme }) => theme.space[4]} - 2px);
  margin-bottom: ${({ theme }) => theme.space[2]};
  height: 64px;
  line-height: 64px;
  padding: 0 ${({ theme }) => theme.space[2]};
  border: 1px solid ${({ theme }) => theme.colors.blue};
  border-radius: ${({ theme }) => theme.misc.borderRadius};
  font-size: 1rem;
  outline: none;
`;

const StyledButton = styled("button")`
  height: 56px;
  min-width: 232px;
  padding: 0 16px;
  background: ${({ theme }) => theme.colors.blueCta};
  color: white;
  border-radius: ${({ theme }) => theme.misc.borderRadius};
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
`;

const ErrorMessage = styled("span")`
  display: block;
  color: ${({ theme }) => theme.colors.red};
  margin: ${({ theme }) => theme.space[2]} 0;
`;

const Login = () => {
  const { setUserRole } = useContext(UserRoleContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [userSlug, setUserSlug] = useState("");
  const [loginPageUserRole, setLoginPageUserRole] = useState("");
  const [login] = useMutation(USER_LOGIN);
  const history = useHistory();

  useEffect(() => {
    if (loginPageUserRole) {
      if (loginPageUserRole === "user") {
        history.push(`/user/${userSlug}`);
      } else if (loginPageUserRole === "admin") {
        history.push("/admin");
      }
    } else {
      history.push("/");
    }
  }, [loginPageUserRole]);

  return (
    <>
      <HeaderBar>
        <LinkItem icon="info" url="#" />
        <LinkItem icon="phone" url="#" />
      </HeaderBar>

      <FormContainer>
        <StyledForm
          onSubmit={(e) => {
            e.preventDefault();
            setErrorMessage(null);

            const res = login({
              variables: { email, password },
              update: (store, { data }) => {
                if (!data) {
                  return null;
                }

                // store.writeQuery({
                //   query: GET_ME,
                //   data: {
                //     me: data.login.user
                //   }
                // });
              },
            });

            res.then((response) => {
              // Dummy logic for errors, make new error handeling in server
              if (response && response.data) {
                if (response.data.login.errorMessage) {
                  setErrorMessage(response.data.login.errorMessage);
                } else {
                  const accessToken = response.data.login.accessToken;
                  const role = response.data.login.user.role;
                  const slug = response.data.login.slug;
                  setAccessToken(accessToken);
                  slug && setUserSlug(slug);
                  role && setLoginPageUserRole(role);
                  role && setUserRole(role);
                  setErrorMessage(null);
                }
              }
            });
          }}
        >
          <h1>Logga in</h1>
          <StyledInput
            value={email}
            placeholder="E-post"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            autoComplete="autocomplete"
          />
          <StyledInput
            type="password"
            value={password}
            placeholder="Lösenord"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            autoComplete="current-password"
          />

          <StyledButton type="submit">Logga in</StyledButton>

          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </StyledForm>
      </FormContainer>
    </>
  );
};

export default Login;
