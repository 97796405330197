import React, { useState, useEffect, useContext } from "react";
import styled from '@emotion/styled';
import { Redirect, useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import USER_LOGOUT from '../../graphql/mutations/USER_LOGOUT';
import { UserRoleContext } from '../../UserRoleContext';
import { ReactComponent as IconLogout } from '../../svg/IconLogout.svg';

const InnerButton = styled('span')`
  display: flex;
  align-items: center;
`;

const LogoutButton = styled('button')`
  padding: .875rem ${({theme}) => theme.space[4]};
  background: ${({theme}) => theme.colors.blueDark};
  border: 0;
  border-top: 1px solid #fff;
  color: #fff;
  font-size: 1rem;
  outline: 0;
  cursor: pointer;

  &:hover {
    background: ${({theme}) => theme.colors.blueDarkDarken};
  }

  ${({ theme, type }) => type === 'userview' && `
    padding: 0;
    border: 0;
    background: transparent;
    
    &:hover {
      background: transparent;
    }

    svg {
      width: 28px;
      height: 28px;
      stroke: ${theme.colors.white};
    }
  `}
`;

const Logout = ({ type }) => {
  const { setUserRole } = useContext(UserRoleContext);
  const [logoutUser, setLogoutUser] = useState(false)
  const [logout] = useMutation(USER_LOGOUT);
  const history = useHistory();

  useEffect(() => {
    if (logoutUser) {
      history.push(`/`);
    }
  }, [logoutUser]);

  const logoutCallback = async () => {
    const response = await logout({ update: (store, { data }) => {
      if (!data) {
        return null;
      }

      setLogoutUser(true);
      setUserRole('');
      // history.push('/');
      return
    }});
  };

  return <LogoutButton type={type} onClick={logoutCallback}><InnerButton><IconLogout />{!type && 'Logga ut'}</InnerButton></LogoutButton>
}

export default Logout;
