import gql from "graphql-tag";

export default gql`
  mutation updateReportMaintenance($_id: String!, $bathID: String!, $date: String!, $time: String!, $values: String) {
  	updateReportMaintenance(_id: $_id, bathID: $bathID, date: $date, time: $time, values: $values) {
  		bathID
      date
      time
      values
  	}
  }
`;
