import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import posed, { PoseGroup } from 'react-pose';
import { ReactComponent as IconPhoneError } from '../../svg/IconPhoneError.svg'
import { ReactComponent as IconCross } from '../../svg/IconCross.svg'

const StyledIconCross = styled(IconCross)`
  width: 22px;
  height: 22px;
  stroke: ${({theme}) => theme.colors.white};
  stroke-width: 2px;
`;

const Header = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({theme}) => theme.space[2]} ${({theme}) => theme.space[4]};
`

const Close = styled('div')`
  height: 22px;
  width: 22px;
`

const StyledIconPhoneError = styled(IconPhoneError)`
  width: 34px;
  height: 34px;
  margin-right: ${({theme}) => theme.space[2]};
`;

export const ModalWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 280px;

  ${({theme, type}) => type === 'error' || type === 'errorCritical' || type == 'warning' ? `
    background: ${theme.colors.redLight};
    border-top: 6px solid ${theme.colors.red};
  ` : `
    background: ${theme.colors.greenLight};
    border-top: 6px solid ${theme.colors.green};
  `}

  ${({theme, type}) => type === 'history' && `
    border-top: none;
    background: ${theme.colors.blueDark};
    color: ${theme.colors.white} !important;
    height: auto;
  `}

  box-shadow: 0 0 44px rgba(0, 0, 0, 0.4);
  z-index: 10;
`;

const ModalMain = styled('div')`
  display: flex;
  justify-content: space-between;
  padding: ${({theme}) => theme.space[2]} ${({theme}) => theme.space[4]};
  color: ${({theme, type}) => type === 'error' || type === 'errorCritical' || type == 'warning' ? theme.colors.redDark: theme.colors.blueDark};

  ${({theme, type}) => type === 'history' ? `
    padding: 0;
    color: #fff;

    ul {
      padding-top: 32px;
      height: 280px;
      overflow: auto;
    }

    li {
      padding: ${theme.space[2]} ${theme.space[4]};
      color: ${theme.colors.blueDark};

      > div {
        min-width: 132px;
      }
    }
  ` : null}
`;

const Info = styled('div')`
  flex: ${({type}) => type === 'history' ? '0 1 100%' : ' 0 1 50%'};

  h1, h2 {
    color: ${({theme, type}) => type === 'error' || type === 'errorCritical' || type == 'warning' ? theme.colors.redDark: theme.colors.blueDark};

    ${({type}) => type === 'history' ? `
      color: #fff;
    ` : null}

    margin-top: ${({theme}) => theme.space[1]};

    span {
      text-transform: capitalize;
    }
  }

  > span {
    font-weight: 600;
    font-size: .85rem;
    text-transform: uppercase;
  }

  p {
    max-width: 520px;
  }
`;

const Contact = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex: 0 1 30%;

  span {
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
  }

  p {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    font-weight: 600;
    margin: ${({theme}) => theme.space[1]} 0;
  }
`;

const ActionButton = styled(posed.span({
  pressed: {
    opacity: .6
  },
  notPressed: {
    opacity: 1
  },
  okPressed: {
    background: 'rgba(203, 24, 64, 0.2)'
  },
  okNotPressed: {
    background: 'rgba(255, 255, 255, 1)'
  }
}))`
  flex: 1 1 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 8%;
  min-height: 72px;
  width: 100%;
  background: ${({theme}) => theme.colors.blueCta};
  color: ${({theme}) => theme.colors.white};
  text-transform: uppercase;
  font-weight: 600;

  span {
    display: flex;
    align-items: center;
  }
`;

const ActionWrapper = styled('div')`
  display: flex;
  height: 8%;
  min-height: 72px;

  > span:first-of-type {
    background: ${({theme}) => theme.colors.white};
    color:  ${({theme}) => theme.colors.red};
  }
`;

const ModalReport = ({children, type, id, substance, valueTxt, buttonTxt, name, message, phone, userView, bath, setModalObject, repportValues, reportsDataView, coverRef}) => {
  console.log(reportsDataView);

  const [buttonPressed, setButtonPressed] = React.useState(false);

  const handelAction = type => {
    setButtonPressed(type);

    setTimeout(() => {
      setButtonPressed(false);
    }, 100);

    setModalObject(ea => {
      delete ea[id];


      if (!Object.keys(ea).length && type !== 'reset') {
        userView({actionType: 'HIDE_MODAL'});
      }

      return {...ea}
    })

    if (type === 'errorCritical') {
      userView({actionType: 'HIDE_MODAL'});
      setTimeout(() => {
        userView({actionType: 'SHOW_MODAL', bath: bath, repportValues: repportValues, substance: substance, type: 'maintenance'});
      }, 1200);
    }

    if (type === 'ok') {
      userView({actionType: 'HIDE_MODAL'});
    }

    if (type === 'reset') {
      setModalObject(ea => {
        ea = {};
        return {...ea}
      })
    }
  }

  useEffect(() => {
    if (type === 'history') {
      coverRef && coverRef.current.addEventListener('click', () => {handelAction('reset')});
    }

    return () => {
      if (type === 'history') {
        coverRef && coverRef.current.removeEventListener('click', {});
      }
    }
  }, []);

  return (
    <ModalWrapper key={id} type={type}>
      <ModalMain type={type}>
        <Info type={type}>
          {type !== 'warning' && type !== 'history' && (<span>Rapport autogenererad</span>)}

          {type === 'ok' && (
            <h2>{name}</h2>
          )}

          {(type === 'error' || type === 'errorCritical') && (
            <h2><span>{name}</span>  {`är för ${valueTxt}`}</h2>
          )}

          {type === 'warning' && (
            <h2>{`Lämna ${name}`}</h2>
          )}

          {type === 'history' && (
            <>
            <Header>
              <h2>{`Historik - ${name}`}</h2>

              {type === 'history' && (
                <Close onClick={() => handelAction('reset')}>
                  <StyledIconCross />
                </Close>
              )}
            </Header>

            {children}
            </>
          )}

          {type !== 'history' && (
            <p>{message}</p>
          )}
        </Info>
        {(type === 'error' || type === 'errorCritical') && (
          <Contact>
            <span>Undrar du något?</span>
            <p><StyledIconPhoneError /> {phone}</p>
          </Contact>
        )}
      </ModalMain>
      {type !== 'history' ? (
        <>
        {type === 'warning' ? (
          <ActionWrapper>
            <ActionButton pose={buttonPressed === 'ok' ? 'okPressed' : 'okNotPressed'} onClick={() => handelAction('ok')}>
              <span>{`Lämna ${name}`}</span>
            </ActionButton>
            <ActionButton pose={buttonPressed === 'reset' ? 'pressed' : 'notPressed'} onClick={() => handelAction('reset')}>
              <span>{`Återgå till ${name}`}</span>
            </ActionButton>
          </ActionWrapper>
        ) : (
          <ActionButton pose={(buttonPressed === 'error' || buttonPressed === 'errorCritical') ? 'pressed' : 'notPressed'} onClick={() => handelAction(type, bath, substance)}>
            <span>{buttonTxt}</span>
          </ActionButton>
        )}
        </>
      ): null}
    </ModalWrapper>
  )
}

export default ModalReport;