import React from "react";
import styled from '@emotion/styled';

const AdminViewContent = styled('div')`
  padding: ${({theme}) => theme.space[3]} ${({theme}) => theme.space[4]};
`;

export default ({ children }) => {

  return (
  <AdminViewContent>
    {children ? children : null}
  </AdminViewContent>
  )
};
