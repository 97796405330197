import React from "react";
import styled from '@emotion/styled';

const AdminViewWrapper = styled('div')`
  display: flex;
  justify-content: stretch;
  height: auto !important;
  min-height: 100vh;

  h1, h2, h3, h4 {
    margin: 0 0 .825rem 0;
  }

  h1 {
    font-size: 2rem;
    line-height: 2.25rem;
    margin-bottom: 1.5rem;
  }

  h2 {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }

  h3 {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }

  input,
  textarea,
  select,
  button {
    outline: none;
  }

  input[type="text"],
  input[type="number"],
  input[type="password"],
  textarea {
    font-size: .875rem;
    padding: .5rem .75rem;
    border: 1px solid ${({theme}) => theme.colors.blueMedium};
    border-radius: ${({theme}) => theme.misc.adminInputBorderRadius};
    ${({theme}) => theme.misc.adminBox};
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    transition: border-color ease-in-out .3;

    &:focus {
      border: 1px solid ${({theme}) => theme.colors.blue};
    }
  }

  button {
    margin: 0;
    outline: none;
  }
`;

export default ({ children }) => {
  return (
    <AdminViewWrapper className="admin-view">
      {children ? children : null}
    </AdminViewWrapper>
  )
};
