import styled from '@emotion/styled';

const FormInputWrapper = styled('div')`
display: flex;
flex-wrap: wrap;

> div {
	flex: 0 1 auto;
	margin-right: ${({ theme }) => theme.space[2]};
	margin-bottom: ${({ theme }) => theme.space[2]};
}

label {
	display: block;
}
`

export default FormInputWrapper;