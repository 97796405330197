import gql from "graphql-tag";

export default gql`
  mutation addBath($name: String!, $bathCategoryID: String!, $location: String) {
  	addBath(name: $name, bathCategoryID: $bathCategoryID, location: $location) {
  		name
      bathCategoryID
      location
  	}
  }
`;
