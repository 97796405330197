import gql from "graphql-tag";

export default gql`
  query($_id: String!) {
    getAdminUserView(_id: $_id) {
      user {
        _id
        role
        email
      }
      business {
        _id
        name
      }
      businesses {
        _id
        name
      }
    }
  }
`;
