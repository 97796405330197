import gql from "graphql-tag";

export default gql`
  mutation updateSettings($customerServicePhone: String, $maintenanceDescription: String) {
  	updateSettings(customerServicePhone: $customerServicePhone, maintenanceDescription: $maintenanceDescription) {
      customerServicePhone
      maintenanceDescription
  	}
  }
`;
