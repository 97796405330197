import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { AdminButtonBasic } from './AdminButtons';
import SEND_MAIL from '../../graphql/mutations/SEND_MAIL';
import Group from './AdminGroup';
import FormInputWrapper from '../Elements/FormInputWrapper';

const Communication = () => {
	const initialSendStatus = { status: 'WAITING', message: null };

	const [sendMail] = useMutation(SEND_MAIL);
	const [sendingMail, setSendingMail] = React.useState(false);
	const [sendStatus, setSendStatus] = React.useState(initialSendStatus);
	const formRef = React.useRef();

	const handleMailSend = async (e) => {
		e.preventDefault();

		const formInputsTotal = e.target.length - 1;
		const formData = {};

		for (let i = 0; i < formInputsTotal; i++) {
			const formInput = e.target[i];

			formData[formInput.getAttribute('id')] = formInput.value;
    }

		if (!formData.to || !formData.subject || !formData.text) {
			setSendStatus({ status: 'ERROR', message: 'Fyll i alla fält' });
			
			return;
		}

		setSendingMail(true);

		try {
			const res = await sendMail({
				variables: { ...formData },
			});
			
			if (res && res.data && res.data.sendMail) {
				setSendStatus({ status: 'OK', message: `Meddelande har skickats till ${formData.to}` });
			}

			if (res && res.data && res.data.sendMail === false) {
				setSendStatus({ status: 'ERROR', message: 'Något gick fel vid utskick av mail' });
			}
			
		} catch (error) {
			console.log('Mail error: ', error);

			setSendStatus({ status: 'ERROR', message: 'Något gick fel vid utskick av mail' });
		}
	};

	React.useEffect(() => {
		if (sendStatus.status === 'OK' || sendStatus.status === 'ERROR') {
			if (formRef.current) {
				const inputs = formRef.current.querySelectorAll('input, textarea');
	
				if (inputs && inputs.length > 0) {
					inputs.forEach(input => {
						input.value = '';
					})
				}
			}
			
			setTimeout(() => {
				setSendStatus(initialSendStatus);
			}, 4000)
		}
	}, [sendStatus])
	
	React.useEffect(() => {
		if (sendingMail === true) {
			if (sendStatus.status === 'OK' || sendStatus.status === 'ERROR') {
				setSendingMail(false);
			}
		}
	}, [sendingMail, sendStatus])

	return (
		<Group>
			<h3>Skicka mail</h3>
			<form onSubmit={handleMailSend} ref={formRef}>
				<FormInputWrapper>
					<div>
						<label htmlFor="to">Mottagarens e-post</label>
						<input type="text" name="to" id="to" />
					</div>
					<div>
						<label htmlFor="to">Ämne</label>
						<input type="text" name="subject" id="subject" />
					</div>
					<div>
						<label htmlFor="text">Meddelande</label>
						<textarea name="text" id="text" rows="4" cols="30" style={{ resize: 'none' }} />
					</div>
				</FormInputWrapper>
				<AdminButtonBasic disabled={sendingMail} type="submit">Skicka</AdminButtonBasic>
			</form>
			{sendingMail && (
				<p>Skickar mail ...</p>
			)}
			{sendStatus.message && (
				<p>{sendStatus.message}</p>
			)}
		</Group>
	)
}

export default Communication;