import gql from "graphql-tag";

export default gql`
  mutation addReportMeasurement($bathID: String!, $date: String!, $time: String!, $status: String!, $values: String) {
  	addReportMeasurement(bathID: $bathID, date: $date, time: $time, status: $status, values: $values) {
  		bathID
      date
      time
      status
      values
  	}
  }
`;
