import gql from "graphql-tag";

export default gql`
  query {
    getMeasurementInputs {
      _id
      name
      adminName
      description
      errorMessage
      hasSubstance
      order
      icon
    }
  }
`;
