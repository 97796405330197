import gql from "graphql-tag";

export default gql`
query {
  getUserView {
		user {
      _id
    }
    bathCategories {
      name
      baths {
        _id
        name
        bathCategoryID
        location
        volume
        factor
        idealValue
        measurementInputs
        maintenanceSubstances
        interval
        forcedMaintenance
        maintenance {
          date
          time
          values
        }
        measurement {
          date
          time
          status
          values
        }
      }
    }
    business {
      name
    }
  }
}
`;
