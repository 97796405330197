import React from "react";
import styled from '@emotion/styled';
import parseJSON from '../../helpers/parseJSON';
import {ReactComponent as IconMeasurement} from '../../svg/IconMeasurement.svg';
import {ReactComponent as IconMaintenance} from '../../svg/IconMaintenance.svg';
import {ReactComponent as IconAlert} from '../../svg/IconAlert.svg';
import {ReactComponent as IconOk} from '../../svg/IconOk.svg';

const StyledIconOk = styled(IconOk)`
  width: 18px;
  height: 18px;
  margin-right: ${({theme}) => theme.space[2]};
  margin-bottom: 2px;
`;

const StyledIconAlert = styled(IconAlert)`
  width: 16px;
  height: 16px;
  margin-right: ${({theme}) => theme.space[2]};
  margin-bottom: 2px;
`;

const IconWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 48px;
  height: 48px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, .1);

  ${({status, theme}) => status === 'ok' ? `
    background: ${theme.colors.green};
  ` : `
    background: ${theme.colors.red};
  `}

  border-radius: 50%;
  margin-right: ${({theme}) => theme.space[2]};

  svg {
    width: 22px;
    height: 22px;
  }
`;

const BatchWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  max-height: 236px;
  flex: 0 1 calc(50% - ${({theme}) => theme.space[1]});
  background-color: ${({theme}) => theme.colors.blueLight};
  border-radius: ${({theme}) => theme.misc.borderRadius};
  margin-bottom: ${({theme}) => theme.space[2]};
  box-shadow: 0px 12px 24px rgba(0, 0, 0, .1);
`;


const Header = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 0 1 30%;
  margin: ${({theme}) => theme.space[2]};
`;

const Status = styled('div')`
  display: flex;
  justify-content: space-between;
  flex: 0 1 70%;
  margin: ${({theme}) => theme.space[1]} ${({theme}) => theme.space[2]} ${({theme}) => theme.space[2]};
`;

const InfoSection = styled('div')`
  display: flex;
  justify-content: space-between;

  span {
    font-size: .85rem;
    font-weight: 600;
    text-transform: uppercase;
  }
`;

const Name = styled('h2')`
  text-transform: uppercase;
  margin: 0;
`;

const Title = styled('h3')`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${({theme}) => theme.space[2]} 0;
`;

const Info = styled('div')`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: ${({theme}) => theme.space[1]} 0;

  span {
    display: inline-flex;
    align-items: center;
    height: 16spx;
  }
`;

const Measurement = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 0 1 calc(50% - ${({theme}) => theme.space[1]});
  box-shadow: 0px 3px 6px rgba(0, 0, 0, .1);
  ${({status, theme}) => status === 'ok' ? `
    background: ${theme.colors.greenLight};
    border: 1px solid ${theme.colors.green};
    color: ${theme.colors.greenDark};

    h3 {
      color: ${theme.colors.greenDark};
    }
  ` : `
    background: ${theme.colors.redLight};
    border: 1px solid ${theme.colors.red};
    color: ${theme.colors.redDark};

    h3 {
      color: ${theme.colors.redDark};
    }
  `}

  border-radius: ${({theme}) => theme.misc.borderRadius};

  ${({isDisable}) => isDisable && `
    opacity: .4;
    pointer-events: none;
  `}
`;

const Maintenance  = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 0 1 calc(50% - ${({theme}) => theme.space[1]});
  box-shadow: 0px 3px 6px rgba(0, 0, 0, .1);
  ${({status, theme}) => status === 'ok' ? `
    background: ${theme.colors.greenLight};
    border: 1px solid ${theme.colors.green};
    color: ${theme.colors.greenDark};

    h3 {
      color: ${theme.colors.greenDark};
    }
  ` : `
    background: ${theme.colors.redLight};
    border: 1px solid ${theme.colors.red};
    color: ${theme.colors.redDark};

    h3 {
      color: ${theme.colors.redDark};
    }
  `}

  border-radius: ${({theme}) => theme.misc.borderRadius};

  ${({isDisable}) => isDisable && `
    opacity: .4;
    pointer-events: none;
  `}
`;

export default ({bathData, userView}) => {
  const bathIntervalObj = bathData.interval && parseJSON(bathData.interval);
  const inputMeasurementsObject = bathData.measurementInputs && parseJSON(bathData.measurementInputs);

  console.log(bathData);

  let activeInputs = false;

  if (inputMeasurementsObject) {
    activeInputs = Object.keys(inputMeasurementsObject).find(item => {
      return inputMeasurementsObject[item].active === 'active';
    })
  }

  let bathIntervalObjToValidate = {};
  
  bathIntervalObj && Object.keys(bathIntervalObj).forEach(type => {
    if (type === 'measurement') {
      bathIntervalObjToValidate[type] = bathIntervalObj[type];
    }

    if (type === 'maintenance' && bathIntervalObj[type].days) {
      const filteredIntervalDays = Object.keys(bathIntervalObj[type].days).filter(day => {
        return bathIntervalObj[type].days[day][0].substance && bathIntervalObj[type].days[day][0].substanceValue;
      });

      let daysObject = {};
      
      filteredIntervalDays.forEach(day => {
        const dayNumber = parseInt(day);

        daysObject[dayNumber] = [];
        
        bathIntervalObj[type].days[dayNumber].forEach((slot, index) => {
          daysObject[dayNumber][index] = bathIntervalObj[type].days[dayNumber][index];
        })
      })

      if (Object.keys(daysObject).length) {
        bathIntervalObjToValidate[type] = {
          days: daysObject
        }
      }
    }
    console.log('******INTERVALOBJECT TO VALIDATE*******')
    console.log(type);
    console.log(bathIntervalObjToValidate[type]);
    console.log('***************************************')
  });

  let intervalStatus = {};
  let repportDate = {};
  let intervalData = {};

  bathIntervalObjToValidate && Object.keys(bathIntervalObjToValidate).map(type => {
    const thisDay = new Date();
    const thisDayNumber = thisDay.getDay();
    const compareReportDay = bathData[type] && new Date(`${bathData[type].date}T${bathData[type].time}`);
    const currentDay = new Date(`${thisDay.toISOString().split('T')[0]}T${thisDay.getHours() < 10 ? '0' : ''}${thisDay.getHours()}:${thisDay.getMinutes() < 10 ? '0' : ''}${thisDay.getMinutes()}:${thisDay.getSeconds() < 10 ? '0' : ''}${thisDay.getSeconds()}`);
    
    const nowHours = thisDay.getHours();
    const nowMinutes = thisDay.getMinutes();
    const nowSeconds = thisDay.getSeconds();
    const isSameDay = bathData[type] && thisDay.toISOString().split('T')[0] ===  new Date(bathData[type].date).toISOString().split('T')[0];
    const nowTimeString = `${nowHours < 10 ? '0' : ''}${nowHours}:${`${nowMinutes < 10 ? '0' : ''}${nowMinutes}`}:${`${nowSeconds < 10 ? '0' : ''}${nowSeconds}`}`;


    let daySpanToCheck = Math.round((currentDay - compareReportDay) / (1000 * 3600 * 24));

    daySpanToCheck = daySpanToCheck > 6 ? 6 : daySpanToCheck;

    let daySpanStart = thisDayNumber - daySpanToCheck;

    daySpanStart = daySpanStart < 1 ? 7 + daySpanStart : daySpanStart;

    let dayToCheck = daySpanStart;

    let daysArray = [];
    let timeArray = [];
    let getDayToDiff = false;
    let dayDiff = 0;
    let firstDaySlots = true;

    for (let index = 0; index <= daySpanToCheck; index++) {
      if (bathIntervalObjToValidate[type].days[dayToCheck]) {    
        if (!getDayToDiff) getDayToDiff = dayToCheck;

        if (index === 0 || thisDayNumber === dayToCheck) {
          const filteredSlots = bathIntervalObjToValidate[type].days[dayToCheck].filter(slot => {
            const slotTime = `${slot.intervalHour < 10 ? '0' : ''}${slot.intervalHour}:${slot.intervalMinute < 10 ? '0' : ''}${slot.intervalMinute}:00`;

            if (bathData[type]) {
              if (isSameDay) {
                return slotTime > bathData[type].time && slotTime < nowTimeString;
              } else if (thisDayNumber === dayToCheck) {
                return slotTime < nowTimeString;
              } else {
                return slotTime > bathData[type].time;
              }
            }
          })
          
          if (filteredSlots.length) {
            daysArray.push(filteredSlots);
            firstDaySlots = false;
          } else {
            getDayToDiff = false;
          }
        } else {
          daysArray.push(bathIntervalObjToValidate[type].days[dayToCheck]);
        }
      }

      dayToCheck = dayToCheck + 1 > 7 ? 1 : dayToCheck + 1;
    }

    if (getDayToDiff < thisDayNumber) {
      dayDiff = thisDayNumber - getDayToDiff;
    }

    if (thisDayNumber < getDayToDiff) {
      dayDiff = 7 - (getDayToDiff - thisDayNumber);
    }

    daysArray[0] && daysArray[0].forEach(slot => {
      const slotTime = `${slot.intervalHour < 10 ? '0' : ''}${slot.intervalHour}:${slot.intervalMinute < 10 ? '0' : ''}${slot.intervalMinute}`;

      timeArray.push(slotTime);
    })

    console.log('****TIMESLSOT ARRAY*******')
    console.log(type);
    console.log(timeArray);
    console.log('**************************')

    let intervalDayString = '';

    const intervalDay = new Date();
    intervalDay.setDate(intervalDay.getDate() - dayDiff);
    intervalDayString = intervalDay.toISOString().split('T')[0];

    const compareIntervalDay = bathData[type] && new Date(`${intervalDayString}T${timeArray[0]}`);

    intervalData[type] = daysArray[0] ? daysArray[0][0] : {};

    const bumpedMinute = parseInt(intervalData[type].intervalMinute) + 1;

    intervalData[type].day = compareIntervalDay && daysArray[0] ? compareIntervalDay.getDate() : '';
    intervalData[type].time = intervalData[type] && daysArray[0] ? `${intervalData[type].intervalHour < 10 ? '0' : ''}${intervalData[type].intervalHour}:${`${bumpedMinute < 10 ? '0' : ''}${bumpedMinute}`}:00` : '';

    console.log('*** LOGG ********************************')
    console.log('COMPARE INTERVAL: ', compareIntervalDay);
    console.log('COMPARE REPORT: ', compareReportDay);
    console.log('*****************************************')
    
    repportDate[type] = compareReportDay;

    if (daysArray[0]) {
      intervalStatus[type] = compareReportDay > compareIntervalDay;
    } else {
      intervalStatus[type] = true;
    }
  });

  let measurementStatus = bathData.measurement ? bathData.measurement.status : 'error';

  const intervalMeasurementsDays = bathIntervalObjToValidate && bathIntervalObjToValidate.measurement && Object.keys(bathIntervalObjToValidate.measurement.days).length;
  let intervalMaintenanceDays = bathIntervalObjToValidate && bathIntervalObjToValidate.maintenance && Object.keys(bathIntervalObjToValidate.maintenance.days).length;

  const measurementDate = bathData.measurement ? bathData.measurement.date : 'Mätdata saknas';
  const maintenanceDate = bathData.maintenance ? bathData.maintenance.date : 'Underhållsdata saknas';

  const measurementTime = bathData.measurement ? bathData.measurement.time : '';
  const maintenanceTime = bathData.maintenance ? bathData.maintenance.time : '';

  if (measurementStatus === 'error' || measurementStatus === 'errorCritical') {
    const compareMaintenanceDay = bathData.maintenance && new Date(`${bathData.maintenance.date}T${bathData.maintenance.time}`);
    
    if (bathData.measurement && (compareMaintenanceDay > repportDate.measurement)) {
      measurementStatus = 'ok';
    }
  }

  let maintenanceStatus = intervalData.maintenance && intervalData.maintenance.substance ? 'errorMaintenance' : measurementStatus;

  const forcedMaintenance = parseJSON(bathData.forcedMaintenance);
  
  if (forcedMaintenance) {
    const forcedMaintenanceValues = parseJSON(forcedMaintenance.values);

    measurementStatus = 'errorCritical';
    maintenanceStatus = 'errorMaintenance';

    const now = new Date();
    const nowHours = now.getHours();
    const nowMinutes = now.getMinutes();
    const nowSeconds = now.getSeconds();
    const nowMonth = now.getMonth();
    const nowDate = now.getDate();

    intervalData.maintenance = {
      day: nowDate,
      intervalHour: "forced",
      intervalMinute: "forced",
      substance: forcedMaintenanceValues.substance,
      substanceValue: forcedMaintenanceValues.amount,
      date: `${now.getFullYear()}-${nowMonth + 1 < 10 ? '0' : ''}${nowMonth + 1}-${nowDate < 10 ? '0' : ''}${nowDate}`,
      time: `${nowHours < 10 ? '0' : ''}${nowHours}:${`${nowMinutes < 10 ? '0' : ''}${nowMinutes}`}:${`${nowSeconds < 10 ? '0' : ''}${nowSeconds}`}`
    }
  }

  return (
    <BatchWrapper>
      <Header>
        <InfoSection>
          <span>{bathData.category}</span>
          <span>{bathData.area}</span>
        </InfoSection>
        <Name>{bathData.name}</Name>
      </Header>
      <Status>
        <Measurement isDisable={(
            !activeInputs 
            || !intervalMeasurementsDays 
            || !intervalMaintenanceDays 
            || bathData.measurementInputs === null 
            || (measurementStatus === 'errorCritical' && bathData.measurement) 
            || (!intervalStatus.maintenance && maintenanceDate !== 'Underhållsdata saknas')
            )} 
          status={
            activeInputs 
            && intervalMeasurementsDays 
            && (measurementStatus === 'ok' 
            && intervalStatus.measurement) 
              ? 'ok' 
              : 'error'
          } 
          onClick={() => {userView({actionType: 'SHOW_MODAL', type: 'measurement', bath: bathData})}} >
          <Title>
            <IconWrapper status={activeInputs && intervalMeasurementsDays && measurementStatus === 'ok' && intervalStatus.measurement ? 'ok' : 'error'}><IconMeasurement /></IconWrapper>
            Mätning
          </Title>
          <Info>
            <span>
              {activeInputs && intervalMeasurementsDays && measurementStatus === 'ok' && intervalStatus.measurement ? (
                <StyledIconOk />
                ) : ( 
                <StyledIconAlert />
              )}

              {activeInputs && intervalMeasurementsDays && (intervalStatus.measurement || (measurementDate === 'Mätdata saknas' && intervalStatus.measurement !== undefined)) ? (
                <span>{measurementDate}</span>
              ) : (
                <>
                {(activeInputs && !intervalMeasurementsDays) ? (
                  <span>Mätningsintervall krävs</span>
                ) : null}
                {!activeInputs && (
                  <span>Aktiva värden krävs</span>
                )}
                {(activeInputs && intervalStatus.measurement !== undefined && intervalMeasurementsDays) ? (
                  <span>Mätning krävs</span>
                ): null}
                </>
              )}
            </span>
            {(activeInputs && intervalStatus.measurement && bathData.measurement && intervalMeasurementsDays) ? (
              <span>{measurementTime}</span>
            ) : null}
          </Info>
        </Measurement>

        <Maintenance isDisable={!intervalMaintenanceDays} 
          status={
            intervalMaintenanceDays 
            && (measurementStatus !== 'errorCritical' && intervalStatus.maintenance) 
            || (measurementDate === 'Mätdata saknas' && intervalStatus.maintenance && intervalMaintenanceDays) 
              ? 'ok' 
              : 'error'
          }
          onClick={() => {
            userView({
              actionType: 'SHOW_MODAL',
              type: 'maintenance',
              bath: bathData,
              status: maintenanceStatus,
              intervalData: intervalData,
            })
          }}>
          <Title>
            <IconWrapper status={intervalMaintenanceDays && (measurementStatus !== 'errorCritical' && intervalStatus.maintenance) || (measurementDate === 'Mätdata saknas' &&  intervalStatus.maintenance && intervalMaintenanceDays) ? 'ok' : 'error'}><IconMaintenance /></IconWrapper>
            Underhåll
          </Title>
          <Info>
            <span>
              {intervalMaintenanceDays && (measurementStatus !== 'errorCritical' && intervalStatus.maintenance) || (measurementDate === 'Mätdata saknas' &&  intervalStatus.maintenance && intervalMaintenanceDays) ? (
                <StyledIconOk />
                ) : (
                <StyledIconAlert />
              )}

              {(intervalMaintenanceDays && intervalStatus.maintenance) ? (
                <span>{maintenanceDate}</span>
              ) : (
                <>
                {!intervalMaintenanceDays && (
                  <span>Underhållsintervall krävs</span>
                )}

                {(intervalMaintenanceDays && !intervalStatus.maintenance) ? (
                  <span>{bathData.maintenance ? 'Underhåll krävs' : 'Underhållsdata saknas'}</span>
                ) : null}
                </>
              )}
            </span>
            {(intervalStatus.maintenance && bathData.maintenance && intervalMaintenanceDays) ? (
              <span>{maintenanceTime}</span>
            ) : null}
          </Info>
        </Maintenance>
      </Status>
    </BatchWrapper>
  )
}
