import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import GET_SUBSTANCES from '../../graphql/queries/GET_SUBSTANCES';
import ADD_SUBSTANCE from '../../graphql/mutations/ADD_SUBSTANCE';
import UPDATE_SUBSTANCE from '../../graphql/mutations/UPDATE_SUBSTANCE';
import { AdminButtonAdd, AdminButtonUpdate } from '../../components/Admin/AdminButtons';
import Breadcrumb from '../../components/Admin/AdminBreadcrumb';
import Group from '../../components/Admin/AdminGroup';

const Measurements = () => {
  const { data: { getSubstances } = {}, loading, error } = useQuery(GET_SUBSTANCES);
  const [initialState, setInitialState] = useState([]);
  const [name, setName] = useState('');
  const [addSubstance] = useMutation(ADD_SUBSTANCE);
  const [updateSubstance] = useMutation(UPDATE_SUBSTANCE);

  useEffect(() => {
    if (getSubstances && getSubstances.length > 0) {
      setInitialState(getSubstances)
    }
  }, [getSubstances]);

  if (loading) return 'Loading'
  if (error) return 'Error'

  const handleChange = (e) => {
    const inputTarget = e.target;
    let inputValue = e.target.value;
    const inputName = e.target.name;
    const arrayIndex = inputTarget.getAttribute('data-index');

    setInitialState(is => {
      is[arrayIndex][inputName] = inputValue;
      return [
        ...is,
      ]
    });
  }

  return (
    <>
      <Breadcrumb />
      <h1>Ämnen</h1>
      <Group>
      {initialState && initialState.length > 0 && (
        <>
        {initialState.map((s, i) => {
          return (
            <form key={s._id} onSubmit={async e => {
              e.preventDefault();

              await updateSubstance({
                variables: initialState[i],
                refetchQueries: [{ query: GET_SUBSTANCES }],
              });

              }}>
              <input type="text" name={'name'} value={s.name} data-index={i} onChange={handleChange} />
              <AdminButtonUpdate type="submit">Uppdatera ämne</AdminButtonUpdate>
            </form>
          )
        })}
        </>
      )}
      </Group>
      <form onSubmit={async e => {
        e.preventDefault();

        await addSubstance({
          variables: { name },
          refetchQueries: [{ query: GET_SUBSTANCES }],
        });

        }}>
        <div>
          <input
          type="text"
          value={name}
          placeholder="Namn"
          onChange={e => {
            setName(e.target.value);
          }}
          />
        </div>
        <AdminButtonAdd type="submit">Lägg till ämne</AdminButtonAdd>
      </form>
    </>
  )
};

export default Measurements;
