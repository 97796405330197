import React from "react";
import styled from '@emotion/styled';
import BathCard from './BathCard';
import parseJSON from '../../helpers/parseJSON';

const BathGridWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: ${({theme}) => theme.space[2]} ${({theme}) => theme.space[4]};
  height: calc(100% - 230px);
  overflow: auto;
`;

const GridHeader = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 94px;
  background: ${({theme}) => theme.colors.white};
  padding: ${({theme}) => theme.space[3]} ${({theme}) => theme.space[4]};

  h1 {
    margin: 0;
  }
`;

const InfoWrapper = styled('div')`
  display: flex;

  > div {
    flex: 0 1 20%;
  }

  span {
    font-size: .85rem;
    font-weight: 600;
    text-transform: uppercase;
  }

  p {
    margin: ${({theme}) => theme.spaceInt[1] / 2}px 0 
  }
`

export default ({bathCategories, business, userView, timeOutController}) => {
  let bathCount = 0
  let intervalData = [];

  const thisDay = new Date();
  const dayNumber = thisDay.getDay();

  const compareThisTime = new Date(`${thisDay.toISOString().split('T')[0]}T${thisDay.getHours() < 10 ? '0' : ''}${thisDay.getHours()}:${thisDay.getMinutes() < 10 ? '0' : ''}${thisDay.getMinutes()}:${thisDay.getSeconds() < 10 ? '0' : ''}${thisDay.getSeconds()}`);
  
  const bathGrid = bathCategories && bathCategories.map((category, index) => {
    const batchPerCategory = category.baths.map((bath, index) => {
      const bathData = Object.assign(bath, {category:category.name});

      bathCount++

      let bathInterval = parseJSON(bathData.interval);

      if (bathInterval && bathInterval.measurement && bathInterval.measurement.days[dayNumber]) {
        bathInterval.measurement.days[dayNumber].forEach(slot => {
          const hour = parseInt(slot.intervalHour);
          const minute = parseInt(slot.intervalMinute);
  
          const compareIntervalTime = new Date(`${thisDay.toISOString().split('T')[0]}T${hour < 10 ? '0' : ''}${hour}:${minute < 10 ? '0' : ''}${minute}:00`);
  
          if (compareThisTime < compareIntervalTime) {
            intervalData.push(compareIntervalTime);
          }
        });
      }

      if (bathInterval && bathInterval.maintenance && bathInterval.maintenance.days[dayNumber]) {
        bathInterval.maintenance.days[dayNumber].forEach(slot => {
          const hour = parseInt(slot.intervalHour);
          const minute = parseInt(slot.intervalMinute);

          const compareIntervalTime = new Date(`${thisDay.toISOString().split('T')[0]}T${hour < 10 ? '0' : ''}${hour}:${minute < 10 ? '0' : ''}${minute}:00`);

          if (compareThisTime < compareIntervalTime) {
            intervalData.push(compareIntervalTime);
          }
        });
      }

      return <BathCard key={index} bathData={bathData} userView={userView} />
    })

    return batchPerCategory
  });

  timeOutController(intervalData, compareThisTime);

  return (
    <>
      <GridHeader>
      <h1>{business}</h1>
      <InfoWrapper>
        <div>
          <span>Driftchef</span>
          <p>-</p>      
        </div>
        <div>
          <span>Badchef</span>          
          <p>-</p> 
        </div>
        <div>
          <span>Antal bad</span>          
          <p>{bathCount}</p> 
        </div>
      </InfoWrapper>
      
      </GridHeader>
      <BathGridWrapper>{bathGrid}</BathGridWrapper>
    </>
  )
}
