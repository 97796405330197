import React from 'react';
import styled from '@emotion/styled';
import { ReactComponent as IconPlus } from '../../svg/IconPlus.svg';
import { ReactComponent as IconUpdate } from '../../svg/IconUpdate.svg';

const SpanUpdate = styled('span')`
  > svg {
    stroke: #fff;
  }
`;

const SpanAdd = styled('span')`
  > svg > path {
    fill: #fff;
  }
`;

const Button = styled('button')`
  background: ${({ theme }) => theme.colors.blueDark};
  border: 1px solid ${({ theme }) => theme.colors.blue};
  border-radius: ${({ theme }) => theme.misc.adminInputBorderRadius};
  ${({ theme }) => theme.misc.adminShadow};
  cursor: pointer;

  > span {
    ${({ theme }) => theme.misc.adminButtonShared}
  }
`;

export const AdminButtonBasic = props => {
  return <Button {...props}><span>{props.children}</span></Button>
}

export const AdminButtonAdd = props => {
  return <Button {...props}><SpanAdd>{props.children}<IconPlus /></SpanAdd></Button>
}

export const AdminButtonUpdate = props => {
  return <Button {...props}><SpanUpdate>{props.children}<IconUpdate /></SpanUpdate></Button>
}

// export default { AdminButtonAdd, AdminButtonUpdate };
