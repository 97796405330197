import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';
import GET_USERS from '../../graphql/queries/GET_USERS';
import ADD_USER from '../../graphql/mutations/ADD_USER';
import { AdminButtonAdd } from '../../components/Admin/AdminButtons';
import Breadcrumb from '../../components/Admin/AdminBreadcrumb';
import Ul from '../../components/Admin/AdminList';

const Users = () => {
  const { data: { getUsers } = {}, loading, error } = useQuery(GET_USERS);
  const [addUser] = useMutation(ADD_USER);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const refetchUsers = () => [{ query: GET_USERS, fetchPolicy: "network-only" }];

  if (loading) return 'Loading';
  if (error) return 'Error';

  return (
    <>
      <Breadcrumb />
      <h1>Alla användare</h1>
      {getUsers && getUsers.length > 0 && (
        <Ul>
          {getUsers.map(u => <li key={u._id}><Link to={`/admin/users/${u._id}`}>{u.email}</Link></li>)}
        </Ul>
      )}

      <form
        onSubmit={async e => {
          e.preventDefault();
          if (email) {
            const response = await addUser({
              variables: { email, password, role: 'user' },
              refetchQueries: refetchUsers,
            });
          }
        }}
      >
        <div>
          <input
            type="text"
            value={email}
            placeholder="Epost"
            onChange={e => {
              setEmail(e.target.value);
            }}
          />
          <input
            type="password"
            value={password}
            placeholder="Lösenord"
            onChange={e => {
              setPassword(e.target.value);
            }}
          />
        </div>
        <AdminButtonAdd type="submit">Lägg till användare</AdminButtonAdd>
      </form>
    </>
  )
};

export default Users;
