import React from 'react';
import styled from '@emotion/styled';
// import SlotInterval from './SlotInterval';
import Group from './AdminGroup';
import Slot from './Slot';

const SlotsContainer = styled('div')`
  display: flex;

  > div {
    // flex: 0 1 110px;
    min-width: 110px;
    margin-right: 1rem;
    &:last-of-type {
      margin-right: 0;
    }
  }
`;

export default ({ initialState, setInitialState, type, substances }) => {

  const handleChange = (e, day, slotIndex) => {
    const inputTarget = e.target;
    const inputName = inputTarget.name;
    let inputValue = inputTarget.value;

    if (inputName === 'active') {
      inputValue = inputTarget.checked ? 'active' : 'inactive';
    } else {
      inputValue = inputTarget.type === 'number' ? parseInt(inputValue, 10) : inputValue;
    }

    setInitialState(is => {
      if (!is.interval[type]) is.interval[type] = {};
      if (!is.interval[type].days) is.interval[type].days = {};
      if (!is.interval[type].days[day]) is.interval[type].days[day] = [];

      const daySlots = is.interval[type].days[day];

      if (!daySlots[slotIndex]) {
        daySlots[slotIndex] = {};
      }

      daySlots[slotIndex] = {
        ...daySlots[slotIndex],
        [inputName]: inputValue,
      }

      return {
        ...is,
      }
    });
  };

  const addSlot = (e, day) => {
    e.preventDefault();
    setInitialState(is => {
      if (!is.interval[type]) is.interval[type] = {};
      if (!is.interval[type].days) is.interval[type].days = {};
      if (!is.interval[type].days[day]) is.interval[type].days[day] = [];

      is.interval[type].days[day].push({ intervalHour: "0", intervalMinute: "0" })

      return {
        ...is,
      }
    });
  }

  const deleteSlot = (e, day, slotIndex) => {
    e.preventDefault();
    setInitialState(is => {

      is.interval[type].days[day].splice(slotIndex, 1);

      if (is.interval[type].days[day].length === 0) {
        delete is.interval[type].days[day];
      }

      return {
        ...is,
      }
    });
  }

  // console.log('initialState in slots?', initialState);

  const days = ['Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag', 'Söndag'];

  const daySlots = days.map((dayLabel, i) => {
    const day = i += 1;
    // const slotIndex = 0;
    const renderSlots = [];
    if (initialState.interval[type] && initialState.interval[type].days && initialState.interval[type].days[day]) {
      const daysLength = initialState.interval[type].days[day].length;

      for (let slotIndex = 0; slotIndex < daysLength; slotIndex += 1) {
        renderSlots.push(
          <Slot
            key={`${type}:${dayLabel}:${slotIndex}`}
            slotData={initialState.interval[type].days[day][slotIndex]}
            day={day}
            slotIndex={slotIndex}
            substances={substances}
            type={type}
            handleChange={handleChange}
            deleteSlot={deleteSlot}
          />
        );
      }
    }

    return (
      <div key={`${type}:${dayLabel}`}>
        <h4>{dayLabel}</h4>
        {renderSlots && renderSlots}
        <button onClick={e => addSlot(e, day)}>Lägg till tid</button>
      </div>
    );
  });


  return (
    <Group>
      <h3>{type === 'maintenance' ? 'Underhåll' : 'Mätning'}</h3>
      <SlotsContainer>{daySlots}</SlotsContainer>
    </Group>
  )
}
