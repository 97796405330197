import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { useQuery, useMutation } from '@apollo/react-hooks';
import GET_MEASUREMENT_INPUTS from '../../graphql/queries/GET_MEASUREMENT_INPUTS';
import ADD_MEASUREMENT_INPUT from '../../graphql/mutations/ADD_MEASUREMENT_INPUT';
import UPDATE_MEASUREMENT_INPUT from '../../graphql/mutations/UPDATE_MEASUREMENT_INPUT';
import { AdminButtonAdd, AdminButtonUpdate } from '../../components/Admin/AdminButtons';
import Breadcrumb from '../../components/Admin/AdminBreadcrumb';
import Group from '../../components/Admin/AdminGroup';

const InputGroup = styled('div')`
  display: flex;
  justify-content: flex-start;

  > * {
    flex: 0 1 0;
  }
`;

const InputForm = styled('form')`
  padding-bottom: 1rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid ${({theme}) => theme.colors.blue};

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const Measurements = () => {
  const { data: { getMeasurementInputs } = {}, loading, error } = useQuery(GET_MEASUREMENT_INPUTS);
  const [initialState, setInitialState] = useState([]);
  const [name, setName] = useState('');
  const [addMeasurementInput] = useMutation(ADD_MEASUREMENT_INPUT);
  const [updateMeasurementInput] = useMutation(UPDATE_MEASUREMENT_INPUT);

  useEffect(() => {
    if (getMeasurementInputs && getMeasurementInputs.length > 0) {
      setInitialState(getMeasurementInputs)
    }
  }, [getMeasurementInputs]);

  if (loading) return 'Loading'
  if (error) return 'Error'

  const handleChange = (e) => {
    const inputTarget = e.target;
    let inputValue = e.target.value;
    const inputName = e.target.name;
    const arrayIndex = inputTarget.getAttribute('data-index');

    if (inputName === 'hasSubstance') {
      inputValue = inputTarget.checked;
    }

    setInitialState(is => {
      is[arrayIndex][inputName] = inputValue;
      return [
        ...is,
      ]
    });
  }

  return (
    <>
      <Breadcrumb />
      <h1>Mätvärden</h1>
      <Group>
      {initialState && initialState.length > 0 && (
        <>
        {initialState.map((is, i) => {
          return (
            <InputForm key={is._id} onSubmit={async e => {
              e.preventDefault();

              initialState[i].order = parseInt(initialState[i].order);

              await updateMeasurementInput({
                variables: initialState[i],
                refetchQueries: [{ query: GET_MEASUREMENT_INPUTS }],
              });

              }}>
              <h3>{is.adminName}</h3>
              <InputGroup>
                <div>
                  <label>
                    Administrationsnamn
                    <input type="text" name={'adminName'} value={is.adminName || ''} data-index={i} onChange={handleChange} />
                  </label>
                  <label>
                    Appnamn
                    <input type="text" name={'name'} value={is.name || ''} data-index={i} onChange={handleChange} />
                  </label>
                </div>
                <div>
                  <label>
                    Beskrivning
                    <textarea rows="4" cols="25" name={'description'} defaultValue={is.description || ''} data-index={i} onChange={handleChange} />
                  </label>
                  <label>
                    Felmeddelande
                    <textarea rows="4" cols="25" name={'errorMessage'} defaultValue={is.errorMessage || ''} data-index={i} onChange={handleChange} />
                  </label>
                </div>
                <div>
                  {/*
                  <p>
                  <label>
                    Ordning
                    <input type="number" name={'order'} value={is.order || 0} data-index={i} onChange={handleChange} />
                  </label>
                  </p>
                  */}
                  <p>
                  <label>Använder åtgärdsämne<input type="checkbox" name={'hasSubstance'} defaultChecked={is.hasSubstance} data-index={i} onChange={handleChange} /></label>
                  </p>
                  <label>
                    Ikon
                    <select name={"icon"} defaultValue={is.icon} data-index={i} onChange={handleChange}>
                      <option value={''}>Ingen ikon</option>
                      <option value={'temperatur'}>Temperatur</option>
                      <option value={'koncentration'}>Koncentration</option>
                    </select>
                  </label>
                </div>
              </InputGroup>
              <AdminButtonUpdate type="submit">Uppdatera mätvärde</AdminButtonUpdate>
            </InputForm>
          )
        })}
        </>
      )}
      </Group>
      {/*
      <form onSubmit={async e => {
        e.preventDefault();

        await addMeasurementInput({
          variables: { name },
          refetchQueries: [{ query: GET_MEASUREMENT_INPUTS }],
        });
      }}>
        <div>
          <input
            type="text"
            value={name}
            placeholder="Namn"
            onChange={e => {
              setName(e.target.value);
            }}
          />
        </div>
        <AdminButtonAdd type="submit">Lägg till mätvärde</AdminButtonAdd>
      </form>
      */}
    </>
  )
};

export default Measurements;
