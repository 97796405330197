import React from "react";
import styled from '@emotion/styled';
import { Link } from "react-router-dom";

import { ReactComponent as IconBack } from '../../svg/IconBack.svg';
import { ReactComponent as IconUpdate } from '../../svg/IconUpdate.svg';

import Logout from './Logout';

const StyledIconUpdate = styled(IconUpdate)`
  width: 28px;
  height: 28px;
  stroke: ${({theme}) => theme.colors.white};
`;

const HeaderBarWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${({theme}) => theme.space[4]};
  height: ${({theme}) => theme.sizes.headerBarHeight};
  background-color: ${({theme}) => theme.colors.blueDark};
  color: ${({theme}) => theme.colors.white};
`;

const LinkContent = styled('ul')`
  display: flex;
  list-style: none;
  height: 28px;

  li {
    margin-left: ${({theme}) => theme.space[4]};

    &:first-of-type {
      opacity: .3;
      line-height: 28px;
    }
  }
`

const BacklinkContent = styled('div')`
`;

const BackLink = styled('span')`
  display: inline-flex;
  align-items: center;
  color: ${({theme}) => theme.colors.white};
  text-decoration: none;

  svg {
    width: 21px;
    height: 20px;
    margin-right: ${({theme}) => theme.space[2]};
  }
`;



export default ({backLink, action=undefined, type, rerenderFunction}) => {
  const thisDay = new Date();
  const renderedTime = `${thisDay.getHours() < 10 ? '0' : ''}${thisDay.getHours()}:${thisDay.getMinutes() < 10 ? '0' : ''}${thisDay.getMinutes()}:${thisDay.getSeconds() < 10 ? '0' : ''}${thisDay.getSeconds()}`;

  return (
    <HeaderBarWrapper>
      <BacklinkContent>
        {backLink ? (
          <BackLink onClick={() =>{action && action()}}>
            <IconBack />
            <span>{backLink}</span>
          </BackLink>
          ) : (
          <Link to="/">IKPAB Optimera</Link>
        )}
      </BacklinkContent>
      {type === 'start' && (
        <LinkContent>
          {/* {linkItems} */}
          <li>
            Senast synk: {renderedTime}
          </li>
          <li>
            <StyledIconUpdate onClick={() => rerenderFunction()} />
          </li>
          <li>
            <Logout type="userview"/>
          </li>
        </LinkContent>
      )}
    </HeaderBarWrapper>
  )
}
