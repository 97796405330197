import React from "react";
import { useQuery } from "@apollo/react-hooks";
import styled from "@emotion/styled";
import GET_APP_SETTINGS from "../../graphql/queries/GET_APP_SETTINGS";
import UserView from "../../components/ViewUser/UserView";
import { AppContextProvider } from "../../AppContext";

const UserViewWrapper = styled("div")`
  height: 100%;
  background-color: ${({ theme }) => theme.colors.grey};
`;

export const User = () => {
  const { data, error, loading } = useQuery(GET_APP_SETTINGS);

  if (error) {
    console.log("GET_APP_SETTINGS Error: ", error);
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!data) {
    return <div>No data...</div>;
  }

  return (
    <UserViewWrapper>
      <AppContextProvider appData={data.getAppSettings}>
        <UserView />
      </AppContextProvider>
    </UserViewWrapper>
  );
};
