import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Admin from './pages/Admin';
import Bath from './pages/Admin/Bath';
import Businesses from './pages/Admin/Businesses';
import Business from './pages/Admin/Business';
import Measurements from './pages/Admin/Measurements';
import Reports from './pages/Admin/Reports';
import Substances from './pages/Admin/Substances';
import User from './pages/Admin/User';
import Users from './pages/Admin/Users';
import Settings from './pages/Admin/Settings';

import AdminViewWrapper from './components/Admin/AdminViewWrapper';
import AdminViewSidebar from './components/Admin/AdminViewSidebar';
import AdminViewContent from './components/Admin/AdminViewContent';

export default () => {

  return (
    <BrowserRouter>
      <AdminViewWrapper>
        <AdminViewSidebar />
        <AdminViewContent>
          <Switch>
            <Route exact path="/admin" component={Admin} />
            <Route exact path="/admin/businesses" component={Businesses} />
            <Route exact path="/admin/businesses/:businessSlug" component={Business} />
            <Route exact path="/admin/businesses/:businessSlug/:bathID" component={Bath} />
            <Route exact path="/admin/measurements" component={Measurements} />
            <Route exact path="/admin/settings" component={Settings} />
            <Route exact path="/admin/substances" component={Substances} />
            <Route exact path="/admin/reports" component={Reports} />
            <Route exact path="/admin/users" component={Users} />
            <Route exact path="/admin/users/:userID" component={User} />
          </Switch>
        </AdminViewContent>
      </AdminViewWrapper>
    </BrowserRouter>
  )
}
