import styled from '@emotion/styled';

const Group = styled('div')`
  margin-bottom: 1.25rem;

  table {
    th {
      text-align: left;
    }
  }
`;

export default Group
