import gql from "graphql-tag";

export default gql`
  query($slug: String!) {
    getBusinessWithSlug(slug: $slug) {
      business {
        _id
        name
      }
      bathCategories {
        _id
        name
        baths {
          _id
          name
          location
          measurementInputs
        }
      }
    }
  }
`;
