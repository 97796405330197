import React from 'react'
import { useQuery } from '@apollo/react-hooks';
import GET_BATH_REPORTS from '../../graphql/queries/GET_BATH_REPORTS';
import ReportDataView from './ReportDataView';

export default ({bathID, offset, limit, type}) => {
  const { data: { getBathReports } = {}, loading, error } = useQuery(GET_BATH_REPORTS, { variables: { _id: bathID, offset, limit, type }, fetchPolicy: "network-only" });

  // console.log(bathID, offset, limit, type)
  // console.log(getBathReports);

  return (
    <>
      {getBathReports ? (
        <ReportDataView type={type} bathReports={getBathReports} />
      ) : null}
    </>
  )
}
