import React from "react";
import ReactDOM from "react-dom";
import { ApolloProvider } from "@apollo/react-hooks";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { onError } from "apollo-link-error";
import { ApolloLink, Observable } from "apollo-link";
import { TokenRefreshLink } from "apollo-link-token-refresh";
import jwtDecode from "jwt-decode";
import { getAccessToken, setAccessToken } from "./handleToken";
import { UserRoleContextProvider } from "./UserRoleContext";
import { AppStartup } from "./AppStartup";
import { Theme, GlobalStyles } from "./components/AppTheme";
import config from "./appConfig";

const cache = new InMemoryCache({});
let appUserRole = "";

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      let handle;
      Promise.resolve(operation)
        .then((operation) => {
          const accessToken = getAccessToken();

          if (accessToken) {
            operation.setContext({
              headers: {
                authorization: `bearer ${accessToken}`,
              },
            });
          }
        })
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    })
);

const client = new ApolloClient({
  link: ApolloLink.from([
    new TokenRefreshLink({
      accessTokenField: "accessToken",
      isTokenValidOrUndefined: () => {
        const token = getAccessToken();

        if (!token) {
          return true;
        }

        try {
          const { exp } = jwtDecode(token);
          if (Date.now() >= exp * 1000) {
            return false;
          } else {
            return true;
          }
        } catch {
          return false;
        }
      },
      fetchAccessToken: () => {
        return fetch(`${config.api}/refresh_token`, {
          method: "POST",
          credentials: "include",
        });
      },
      handleFetch: (accessToken, role) => {
        // setUser(accessToken, role);
        setAccessToken(accessToken);
        appUserRole = role;
      },
      handleError: (err) => {
        console.warn("Your refresh token is invalid. Try to relogin");
        console.error(err);
      },
    }),
    onError(({ graphQLErrors, networkError }) => {
      graphQLErrors && console.log("GraphQL Error: ", graphQLErrors);
      networkError && console.log("Network Error: ", networkError);
    }),
    requestLink,
    new HttpLink({
      uri: `${config.api}/graphql`,
      credentials: "include",
    }),
  ]),
  cache,
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <UserRoleContextProvider appUserRole={appUserRole}>
      <GlobalStyles />
      <Theme>
        <AppStartup />
      </Theme>
    </UserRoleContextProvider>
  </ApolloProvider>,
  document.getElementById("root")
);
