import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { AdminButtonAdd } from '../../components/Admin/AdminButtons';
import ADD_BATH from '../../graphql/mutations/ADD_BATH';

export default ({ bathCategories, refetchBusiness }) => {
  const [name, setName] = useState('');
  const [bathCategoryID, setBathCategoryID] = useState('');
  const [addBath] = useMutation(ADD_BATH);

  return (
    <form onSubmit={async e => {
      e.preventDefault();
      if (name && bathCategoryID) {
        const response = await addBath({
          variables: { name, bathCategoryID },
          refetchQueries: refetchBusiness,
        });
        console.log('response?', response);
      }
    }}
    >
      <div>
        <input
        type="text"
        value={name}
        placeholder="Namn"
        onChange={e => setName(e.target.value) }
        />
      </div>
      <div>
        {bathCategories && bathCategories.length > 0 && (
          <select onChange={e => setBathCategoryID(e.target.value) }>
            <option value="">Välj kategori</option>
            {bathCategories.map(category => <option value={category._id} key={category._id}>{category.name}</option>)}
          </select>
        )}
      </div>

      <AdminButtonAdd type="submit">Lägg till bad</AdminButtonAdd>
    </form>
  )
}
