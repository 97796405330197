import gql from "graphql-tag";

export default gql`
  mutation addBathCategory($name: String!, $businessID: String!) {
  	addBathCategory(name: $name, businessID: $businessID) {
  		name
      businessID
  	}
  }
`;
