import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { useQuery, useMutation } from '@apollo/react-hooks';
import Breadcrumb from '../../components/Admin/AdminBreadcrumb';
import { AdminButtonUpdate } from '../../components/Admin/AdminButtons';
import GET_SETTINGS from '../../graphql/queries/GET_SETTINGS';
import UPDATE_SETTINGS from '../../graphql/mutations/UPDATE_SETTINGS';

const Settings = () => {
  const { data: { getSettings } = {}, loading, error } = useQuery(GET_SETTINGS);
  const [updateSettings] = useMutation(UPDATE_SETTINGS);
  const [initialState, setInitialState] = useState({});

  console.log('getSettings', getSettings);

  useEffect(() => {
    if (getSettings && Object.entries(getSettings).length > 0) {
      setInitialState(getSettings)
    }
  }, [getSettings]);

  if (loading) return 'Loading'
  if (error) return 'Error'
  if (!getSettings) return 'No data'

  const handleChange = (e) => {
    const inputTarget = e.target;
    const inputName = e.target.name;
    let inputValue = e.target.value;

    setInitialState(is => {
      is[inputName] = inputValue;
      return {
        ...is,
      }
    })
  }

  return (
    <>
      <Breadcrumb />
      <form onSubmit={async e => {
        e.preventDefault();
        const response = await updateSettings({
        variables: { ...initialState }
        });
      }}
      >
        <div>
          <label htmlFor="customerServicePhone">Kundservice telefon</label>
        </div>
        <input
          type="text"
          id="customerServicePhone"
          name="customerServicePhone"
          onChange={handleChange}
          value={initialState.customerServicePhone || ''}
        />

        <div>
          <label htmlFor={"maintenanceDescription"}>Underhållsbeskrivning</label>
        </div>
        <textarea
          id="maintenanceDescription"
          rows="4"
          cols="25"
          name="maintenanceDescription"
          defaultValue={initialState.maintenanceDescription || ''}
          onChange={handleChange}
        />
        <div>
          <AdminButtonUpdate type="submit">Uppdatera inställningar</AdminButtonUpdate>
        </div>
      </form>
    </>
  )
}

export default Settings;
