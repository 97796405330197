import React from "react";
import styled from '@emotion/styled';
import { Link } from "react-router-dom";

import {ReactComponent as IconPhone} from '../../svg/IconPhone.svg'
import {ReactComponent as IconInfo} from '../../svg/IconInfo.svg'

const StyledIconInfo = styled(IconInfo)`
  width: 24px;
  height: 24px;
`;

const StyledIconPhone = styled(IconPhone)`
  width: 24px;
  height: 24px;
`;

export default ({icon, url}) => {
  const icons = {
    phone: StyledIconPhone,
    info: StyledIconInfo,
  };

  const FallBackIcon = styled('span')``;

  const LinkIcon = icons[icon] || FallBackIcon;
  
  return (
    <Link to={url}>
      <LinkIcon />      
    </Link>
  )
}