import gql from "graphql-tag";

export default gql`
  mutation updateSubstance($_id: String!, $name: String) {
  	updateSubstance(_id: $_id, name: $name) {
      _id
  		name
  	}
  }
`;
