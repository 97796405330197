import React from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import GET_ADMIN_USER_VIEW from '../../graphql/queries/GET_ADMIN_USER_VIEW';
import UPDATE_USER from '../../graphql/mutations/UPDATE_USER';
import Breadcrumb from '../../components/Admin/AdminBreadcrumb';
import UserHandler from '../../components/Admin/UserHandler';

const User = ({ match }) => {
  const { userID } = match.params;
  const { data: { getAdminUserView } = {}, loading, error } = useQuery(GET_ADMIN_USER_VIEW, { variables: { _id: userID }, fetchPolicy: "network-only" });
  const [updateUser] = useMutation(UPDATE_USER);

  if (loading) return 'Loading'
  if (error) return 'Error'
  if (!getAdminUserView) return 'No user data'

  const userEmail = getAdminUserView && getAdminUserView.user && getAdminUserView.user.email;

  return (
    <>
      <Breadcrumb thirdLast={userEmail} />
      <UserHandler getAdminUserView={getAdminUserView} updateUser={updateUser} userID={userID}/>
    </>
  )
};

export default User;
