import React, { createContext, useState } from 'react';

const UserRoleContext = createContext();

const UserRoleContextProvider = React.memo(props => {
  const appUserRole = props.appUserRole;
  const [userRole, setUserRole] = useState(appUserRole);

  return <UserRoleContext.Provider value={{ userRole, setUserRole }}>{props.children}</UserRoleContext.Provider>;
});

export { UserRoleContext, UserRoleContextProvider };
