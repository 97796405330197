import React from "react";
import styled from '@emotion/styled';

const ItemSpan = styled('span')`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 52px;
  font-size: 1.2rem;
  ${({ theme, type }) => type === 'active' && `
    background: ${theme.colors.greyLight};
  `}
`;

const List = styled('div')`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
height: calc(100% - 56px);
position: relative;

&:before, &:after {
  content: '';
  width: 100%;
  position: absolute;
  left: 0;
}

&:before {
  height: 30px;
  top: 0;
  background: -moz-linear-gradient(bottom, rgba(255, 255, 255, 0) 0%, white 50%);
  background: -webkit-linear-gradient(bottom, rgba(255, 255, 255, 0) 0%, white 50%);
  background: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, white 50%);
  z-index: 2;
}

&:after {
  height: 30px;
  bottom: 0;
  //border-radius: 6px;
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, white 50%);
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, white 50%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 50%);
}
`;

const ListInner = styled('div')`
width: 100%;
height: 100%;
position: relative;
overflow: scroll;
`;

const ScrollContent = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 18px 0;
`;

export default ({ currentValue, callback, values }) => {
  const listRef = React.useRef(null);

  React.useEffect(() => {
    if (currentValue && listRef && listRef.current) {
      const containerHeight = listRef.current.clientHeight;
      const child = listRef.current.children[0];
      const items = child.children;
      const selectedItem = items[currentValue];
      if (selectedItem) {
        const itemPosition = (selectedItem.clientHeight * currentValue) - (selectedItem.clientHeight * 4);  
        listRef.current.scrollTo(0, itemPosition)
      };
    }

  }, [listRef]);

  const Values = () => {
    // const val = [];

    // for (let i = 1; i < 21; i++) {
    //   val.push(<ItemSpan type={i === currentValue ? 'active' : ''} key={i} onClick={() => callback(i, 'kg')}>{i}</ItemSpan>);
    // }

    // return [...val]
  }

  return (
    <List>
      <ListInner ref={listRef}>
        <ScrollContent>
          {values.map((item, index) => (
            <ItemSpan key={index} type={item === currentValue ? 'active' : ''} onClick={() => callback(item)}>{item}</ItemSpan>
          ))}
        </ScrollContent>
      </ListInner>
    </List>
  )
}
