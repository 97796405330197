import gql from "graphql-tag";

export default gql`
  mutation addBusiness($name: String!, $slug: String!) {
  	addBusiness(name: $name, slug: $slug) {
  		name
      slug
  	}
  }
`;
