import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';
import Breadcrumb from '../../components/Admin/AdminBreadcrumb';
import BathMeasurements from '../../components/Admin/BathMeasurements';
import BathHandler from '../../components/Admin/BathHandler';
import GET_ADMIN_BATH_VIEW from '../../graphql/queries/GET_ADMIN_BATH_VIEW';
import UPDATE_BATH from '../../graphql/mutations/UPDATE_BATH';
import Communication from '../../components/Admin/Communication';

const Bath = ({ match }) => {
  const { businessSlug, bathID } = match.params;
  const { data: { getAdminBathView } = {} } = useQuery(GET_ADMIN_BATH_VIEW, { variables: { _id: bathID, businessSlug }, fetchPolicy: "network-only" });
  const refetchBathView = () => [{ query: GET_ADMIN_BATH_VIEW, variables: { _id: bathID, businessSlug }, fetchPolicy: "network-only" }];
  const [updateBath] = useMutation(UPDATE_BATH)

  if (!getAdminBathView) return 'No bath view data';

  const bath = getAdminBathView.bath;
  const businessName = getAdminBathView.business.name;

  return (
    <>
      <Breadcrumb thirdLast={businessName} fourthLast={bath.name} />
      <BathHandler bathID={bathID} updateBath={updateBath} getAdminBathView={getAdminBathView} refetchBathView={refetchBathView} />
      <BathMeasurements bathID={bathID} getAdminBathView={getAdminBathView} />
      <Communication />
      <Link to={`/admin/businesses/${businessSlug}`}>Tillbaka till {businessName}</Link>
    </>
  )
};

export default Bath;
