import gql from "graphql-tag";

export default gql`
  query($_id: String!, $businessSlug: String!) {
    getAdminBathView(_id: $_id, businessSlug: $businessSlug) {
      business {
        name
      }
      bath {
        _id
        name
        location
        volume
        factor
        idealValue
        bathCategoryID
        maintenanceSubstances
        interval
        measurementInputs
        forcedMaintenance
      }
      bathCategories {
        _id
        name
      }
      measurementInputs {
        _id
        name
        adminName
        hasSubstance
      }
      substances {
        _id
        name
      }
    }
  }
`;
