import gql from "graphql-tag";

export default gql`
  mutation addUser($email: String!, $business: String, $password: String!, $role: String!) {
  	addUser(email: $email, businessID: $business, password: $password, role: $role) {
  		email
  		password
      role
      businessID
  	}
  }
`;
