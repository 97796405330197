import React from 'react';
// import InputSelectUnit from './InputSelectUnit';

export default({ id, name, adminName, initialState, setInitialState, hasSubstance, substances }) => {
  const input = initialState && initialState.measurements && initialState.measurements[id];
  const active = input && input.active === 'active' ? true : false;
  const min = input && input.inputMin ? input.inputMin : 0;
  const max = input && input.inputMax ? input.inputMax : 0;
  const substance = input && input.substance ? input.substance : '';
  // const unit = input && input.unit ? input.unit : '';
  // const unit = 'kg';
  // const unitInterval = input && input.unitInterval ? input.unitInterval : 0;

  const handleMeasurement = e => {
    const inputTarget = e.target;
    const inputName = inputTarget.name;
    let inputValue = inputTarget.value;

    if (inputName === 'active') {
      inputValue = inputTarget.checked ? 'active' : 'inactive';
    } else {
      inputValue = inputValue.replace(',', '.');
    }

    setInitialState(is => {
      is.measurements[id] = is.measurements[id] ? is.measurements[id] : {};
      is.measurements[id][inputName] = inputValue;

      return {
        ...is,
      }
    });
  };

  return (
    <>
      <tr>
      <td>
        <label>
          <input type="checkbox" name={'active'} checked={active || false} onChange={handleMeasurement} />{adminName}
        </label>
      </td>
      <td>
        <input
          type="text"
          name={'inputMin'}
          placeholder={'Min'}
          value={min}
          onChange={handleMeasurement}
        />
        </td>
        <td>
          <input
            type="text"
            name={'inputMax'}
            placeholder={'Max'}
            value={max}
            onChange={handleMeasurement}
          />
        </td>
        {hasSubstance && (
          <>
            {substances && (
              <td>
                <select key={'substance'+substance} name={'substance'} onChange={handleMeasurement} defaultValue={substance || null}>
                <option value="">Ingen vald</option>
                  {substances.map(s => <option key={s._id} value={s._id}>{s.name}</option> )}
                </select>
              </td>
            )}
            {/*
          <td>
            <InputSelectUnit handleMeasurement={handleMeasurement} unit={unit} />
          </td>
          */}
          {/*
          <td>
            <input
              type="number"
              name={'unitInterval'}
              placeholder={'Enhetsintervall'}
              value={unitInterval}
              onChange={handleMeasurement}
            />
          </td>
          */}
          </>
        )}
      </tr>
    </>
  )
};
