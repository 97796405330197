import React, {Fragment} from "react";
import styled from '@emotion/styled';
import posed, { PoseGroup } from 'react-pose';
import HeaderBar from '../Blocks/HeaderBar';
import MeasureBoard from './MeasureBoard';
import MaintenanceBoard from './MaintenanceBoard';
import ModalReport from '../ViewUser/ModalReport'

const PosedModalWrapper = styled(posed.div({
  preEnter: {
    y: 300,
  },
  enter: {
    y: 0,
    delay: 150,
    transition: {
      y: { type: 'spring', stiffness: 880, damping: 36 },
      default: { duration: 300 }
    }
  },
  exit: {
    y: 300,
    delay: 200,
    transition: {
      y: { ease: 'easeOut', duration: 600 }
    }
  },
}))`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
`;

const Cover = styled(posed.div({
  enter: {
    opacity: 1,
    transition: { duration: 100 }
  },
  exit: {
    delay: 560,
    opacity: 0,
    transition: { duration: 100 }
  },
}))`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1;
`;

const MeasureView = styled('div')`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
`

const ModalView = ({type, bath, repportValues, intervalData, status, userView}) => {
  const [modalObject, setModalObject] = React.useState({});
  let typeText = '';

  switch (true) {
    case type === 'measurement':
      typeText = 'Mätning';    
      break;

      case type === 'maintenance':
      typeText = 'Underhåll';    
      break;
  
    default:
      typeText = 'Ingen typ definierad'
      break;
  }

  const backLinkText = `${bath ? bath.name : 'Inget bad definierat'} - ${typeText}`;

  const handleModal = backLinkText => {
    let modalObject = {}

    let type = typeText.toLowerCase();

    modalObject[0] = {
      type: 'warning',
      name: type,
      message: `Du är påväg att lämna utan att ha registrerat ${type}. Är du säker på att du vill fortsätta?`,
      buttonTxt: 'Stäng'
    };

    setModalObject(modalObject);
  }
  
  return (
    <>
    {bath ? (
      <MeasureView>
        <HeaderBar 
          backLink={backLinkText} 
          action={() => { 
            handleModal(backLinkText) 
          }} 
        />
        {type === 'measurement' && (
          <MeasureBoard bath={bath} userView={userView} />
        )}

        {type === 'maintenance' && (
          <MaintenanceBoard bath={bath} repportValues={repportValues} intervalData={intervalData} status={status} userView={userView} />
        )}
        <PoseGroup preEnterPose='preEnter'>
          {Object.keys(modalObject).length ? Object.keys(modalObject).map((id, index) => (
            <PosedModalWrapper key={index}>
              <ModalReport
                key={index}
                type={modalObject[id].type}
                id={id}
                valueTxt={modalObject[id].valueTxt}
                buttonTxt={modalObject[id].buttonTxt}
                name={modalObject[id].name}
                message={modalObject[id].message}
                phone={modalObject[id].phone}
                setModalObject={setModalObject}
                userView={userView}
                bath={bath}
              />
            </PosedModalWrapper>
          )): null}
          {Object.keys(modalObject).length ? (
            <Cover key='Cover'/>
          ): null}
        </PoseGroup>
      </MeasureView>
    ) : null}
    </>
  )
}

export default ModalView;