import gql from "graphql-tag";

export default gql`
  mutation updateMeasurementInput($_id: String!, $name: String, $adminName: String!, $description: String, $errorMessage: String, $hasSubstance: Boolean, $order: Int! $icon: String) {
  	updateMeasurementInput(_id: $_id, name: $name, adminName: $adminName, description: $description, errorMessage: $errorMessage, hasSubstance: $hasSubstance, order: $order, icon: $icon) {
      _id
  		name
      adminName
      description
      errorMessage
      hasSubstance
      order
      icon
  	}
  }
`;
