import React, {useContext, Fragment} from 'react';
import { AppContext } from '../../AppContext';
import styled from '@emotion/styled';
import parseJSON from '../../helpers/parseJSON';
import { ReactComponent as IconOk } from '../../svg/IconOk.svg';
import { ReactComponent as IconAlert } from '../../svg/IconAlert.svg';
import AdminReportEditor from '../Admin/AdminReportEditor';

const Header = styled('li')`
  position: absolute;
  top: 60px;
  width: calc(100% - 62px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.colors.white} !important;
  background: ${({ theme }) => theme.colors.blueDark};
  z-index: 9;

  .admin-view & {
    position: relative;
    top: 0;
    background: transparent;
  }

  > span {
    width: 98px;

    &.status {
      width: 16px;
    }
  }

  .right {
    text-align: right;
  }
`;

const AdminHeader = styled(Header)`
  display: grid;
  grid-template-rows: 1fr;
  grid-column-gap: 8px;
  width: auto;

  span {
    width: auto;
    text-align: left !important;
  }
`;

const AdminHeaderMaintenance = styled (AdminHeader)`
  grid-template-columns: repeat(7, 1fr);
`;

const AdminHeaderMeasurement = styled (AdminHeader)`
  grid-template-columns: repeat(11, 1fr);
`;

export const DataRow = styled('li')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .25rem .725rem;

  span {
    width: 98px;

    &.right {
      text-align: right;
    }

    &.status {
      display: inline-flex;
      align-items: center;
      width: 16px;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  ${({ index, type, theme }) => `
    background: ${index % 2 == 0 ? theme.colors.white : theme.colors.blueLight};

    ${(type !== 'ok' && type !== undefined) ? `
      background: ${index % 2 == 0 ? theme.colors.redMedium : theme.colors.redLight};
    ` : ''}
  `};
`;

export const AdminDataRowMeasurement = styled(DataRow)`
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 8px;

  span {
    width: auto !important;
    text-align: left !important;
  }
`;

export const AdminDataRowMaintenance = styled(DataRow)`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 8px;

  span {
    width: auto !important;
    text-align: left !important;
  }
`;

const ValueItem = styled('span')`
  width: 98px;
  text-align: right;
  ${({status, value}) => (status === 'error' && value !== '-') ? `
    color: red;
  `: ''}
`;

export const EditableInput = styled('input')`
  max-width: 74px; 
  padding: .5rem 0;
  text-align: center;
`;

const getEditableValue = (reportValues, itemId) => {
  const maybeReport = reportValues.find(value => value.id === itemId);

  if (maybeReport && maybeReport.value) {
    return maybeReport.value;
  }

  return '-';
}

const ReportDataView = ({ admin, bath, substances, deleteReportByID, type, bathReports, refetchBathReports }) => {
  const { appState } = useContext(AppContext);
  const { appData } = appState;

  const dataInputs = type === 'measurement' ? appData.measurementInputs :
    [
      {
        order: 1,
        name: 'Ämne'
      },
      {
        order: 2,
        name: 'Mängd'
      },
    ];
  const bathReportsData = type === 'measurement' ? bathReports.measurement : bathReports.maintenance;

  const headerArray = dataInputs && dataInputs.reduce((array, item) => {
    array[item.order - 1] = item.adminName ? item.adminName : item.name;

    return array;
  }, [])

  const ReportValues = ({ reportValues, admin, editMode, onChange = null }) => {
    const valuesArray = dataInputs && dataInputs.reduce((array, item, i) => {
      if (type === 'measurement') {
        const reportIndex = reportValues.findIndex(i => i.id === item._id);

        array[item.order - 1] = {
          id: reportIndex !== -1 ? reportValues[reportIndex].id : undefined,
          value: reportIndex !== -1 ? reportValues[reportIndex].value : '-',
          status: reportIndex !== -1 ? reportValues[reportIndex].status : undefined
        }
      } else {
        array[item.order - 1] = {
          id: reportValues[item.order - 1].id,
          value: reportValues[item.order - 1].value,
          status: reportValues[item.order - 1].status
        }
      }

      if (editMode) {
        if (type === 'measurement') {
          array[item.order - 1].editModeInputId = dataInputs[i]._id;
        }
      }

      return array;
     }, []);

    return (
      <>
        {valuesArray && valuesArray.map((item, index) => (
          <Fragment key={index}>
            {admin && editMode 
              ? 
              <>
                <EditableInput type="text" value={getEditableValue(reportValues, item.editModeInputId)} data-id={item.editModeInputId} onChange={onChange} />
              </>
              : <ValueItem status={item.status} value={item.value}>{item.value}</ValueItem>
            } 
          </Fragment>
        ))}
      </>
    )
  }

  const [editReport, setEditReport] = React.useState();

  const setReportEditMode = (report) => {    
    setEditReport(report);
  }

  const exitEditMode = () => {    
    setEditReport(null);
  }

  const RowType = admin 
    ? type === 'measurement'
      ? AdminDataRowMeasurement
      : AdminDataRowMaintenance
    : DataRow

  const HeaderType = admin 
    ? type === 'measurement'
      ? AdminHeaderMeasurement
      : AdminHeaderMaintenance
    : Header;

  const reportRows = bathReportsData && bathReportsData.map((br, index) => {
    return (
      <Fragment key={index}>
        {index === 0 && (
          <HeaderType>
            <span>Datum</span>
            <span>Tid</span>
            {headerArray && headerArray.map((item, index) => (
              <span className='right' key={index}>{item}</span>
            ))}
            {/* {type === 'measurement' && <span className='spacer'></span>} */}
            <span className='status'>{admin ? 'Status' : ''}</span>
            {admin && deleteReportByID && (
              <span>Ta bort</span>
            )}
            {admin && (
              <span>Redigera</span>
            )}
          </HeaderType>
        )}
        {editReport && editReport._id && editReport._id === br._id  
        ? <AdminReportEditor type={type} bath={bath} substances={substances} bathReport={br} deleteReportByID={deleteReportByID} ReportValues={ReportValues} exitEditMode={exitEditMode} refetchBathReports={refetchBathReports} />
      : (
        <Fragment key={br._id}>          
          <RowType key={br._id} type={br.status} index={index}>
            <span>{br.date}</span>
            <span>{br.time}</span>

            <ReportValues reportValues={parseJSON(br.values)} admin={admin} />

            <span className='status'>
              {br.status === 'ok' || br.status === undefined ? <IconOk /> : <IconAlert /> }
            </span>
            {admin && deleteReportByID && (
              <span className='remove right'><button onClick={() => deleteReportByID(br._id, br.status)}>Ta bort</button></span>
            )}
            {admin && (
              <span className='update right'><button onClick={() => setReportEditMode(br)}>Redigera</button></span>
            )}
          </RowType>
        </Fragment>
      )}
      </Fragment>
    )        
  })

  return <ul>{reportRows}</ul>
}

export default ReportDataView