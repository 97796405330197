import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Redirect, useHistory } from "react-router-dom";
import Breadcrumb from "../../components/Admin/AdminBreadcrumb";
import AddBath from "../../components/Admin/AddBath";
import Group from "../../components/Admin/AdminGroup";
import Ul from "../../components/Admin/AdminList";
import { AdminButtonAdd } from "../../components/Admin/AdminButtons";
import { ReactComponent as IconEdit } from "../../svg/IconEdit.svg";
import { ReactComponent as IconCross } from "../../svg/IconCross.svg";
import GET_BUSINESS_WITH_SLUG from "../../graphql/queries/GET_BUSINESS_WITH_SLUG";
import DELETE_BATH from "../../graphql/mutations/DELETE_BATH";
import ADD_BATH_CATEGORY from "../../graphql/mutations/ADD_BATH_CATEGORY";
import DELETE_BATH_CATEGORY from "../../graphql/mutations/DELETE_BATH_CATEGORY";
import DELETE_BUSINESS from "../../graphql/mutations/DELETE_BUSINESS";

const ButtonDeleteBathCategory = styled("button")``;

const StyledIconCross = styled(IconCross)`
  stroke-width: 2;
`;

const ButtonDeleteBath = styled("button")`
  background: transparent;
  border: 0;
  padding: 0;
`;

const BathCategoryTop = styled("div")`
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  margin-bottom: 0.5rem;
`;

const IconWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > * {
    height: 1rem;
    margin-right: 0.75rem;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const ListInner = styled("span")`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BathCategory = styled("div")`
  margin-bottom: ${({ theme }) => theme.space[3]};

  h3 {
    display: block;
    margin-bottom: 0;
  }

  svg {
    width: 1rem;
    height: 1rem;
    fill: ${({ theme }) => theme.colors.blueDark};
    stroke: ${({ theme }) => theme.colors.blueDark};
  }

  li {
    padding: 0.25em 1em;
    > a {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 400;
    }
  }
`;

const Business = ({ match }) => {
  const { businessSlug } = match.params;
  const { data: { getBusinessWithSlug } = {}, loading, error } = useQuery(
    GET_BUSINESS_WITH_SLUG,
    {
      variables: { slug: businessSlug },
      fetchPolicy: "network-only",
    }
  );
  const business = getBusinessWithSlug && getBusinessWithSlug.business;
  const businessBathCat =
    getBusinessWithSlug && getBusinessWithSlug.bathCategories;
  const businessID = business && business._id;

  const [name, setName] = useState("");
  const [status, setStatus] = useState("");

  const history = useHistory();

  const [deleteBath] = useMutation(DELETE_BATH);
  const [addBathCategory] = useMutation(ADD_BATH_CATEGORY);
  const [deleteBathCategory] = useMutation(DELETE_BATH_CATEGORY);
  const [deleteBusiness] = useMutation(DELETE_BUSINESS);

  const refetchBusiness = () => [
    {
      query: GET_BUSINESS_WITH_SLUG,
      variables: { slug: businessSlug },
      fetchPolicy: "network-only",
    },
  ];

  if (loading) return "Loading";
  if (error) return "Error";
  if (!business) return "No user";

  return (
    <>
      <Group>
        <Breadcrumb thirdLast={business.name} />
        <h1 data-business-id={business._id}>{business.name}</h1>
        <p>{status}</p>
        {businessBathCat && businessBathCat.length > 0 && (
          <>
            <h2>Kategorier</h2>
            {businessBathCat.map((bc) => {
              return (
                <BathCategory key={bc._id}>
                  <BathCategoryTop>
                    <h3>{bc.name}</h3>
                    <ButtonDeleteBathCategory
                      onClick={async (e) =>
                        await deleteBathCategory({
                          variables: { id: bc._id },
                          refetchQueries: refetchBusiness,
                        })
                      }
                    >
                      Ta bort badkategori
                    </ButtonDeleteBathCategory>
                  </BathCategoryTop>
                  {bc.baths && bc.baths.length > 0 && (
                    <Ul key={bc._id}>
                      {bc.baths.map((b) => {
                        return (
                          <li key={b._id}>
                            <ListInner>
                              <Link
                                to={`/admin/businesses/${businessSlug}/${b._id}`}
                              >
                                {b.name}
                              </Link>
                              <IconWrapper>
                                <Link
                                  to={`/admin/businesses/${businessSlug}/${b._id}`}
                                >
                                  <IconEdit />
                                </Link>
                                <ButtonDeleteBath
                                  onClick={async (e) =>
                                    await deleteBath({
                                      variables: { bathID: b._id },
                                      refetchQueries: refetchBusiness,
                                    })
                                  }
                                >
                                  <StyledIconCross />
                                </ButtonDeleteBath>
                              </IconWrapper>
                            </ListInner>
                          </li>
                        );
                      })}
                    </Ul>
                  )}
                </BathCategory>
              );
            })}
          </>
        )}
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            if (name && businessID) {
              await addBathCategory({
                variables: { name, businessID },
                refetchQueries: refetchBusiness,
              });
            }
          }}
        >
          <div>
            <input
              type="text"
              value={name}
              placeholder="Namn"
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>
          <AdminButtonAdd type="submit">Lägg till badkategori</AdminButtonAdd>
        </form>
      </Group>

      <Group>
        <AddBath
          bathCategories={businessBathCat}
          refetchBusiness={refetchBusiness}
        />
      </Group>

      <Link to={"/admin/businesses"}>Tillbaka till alla företag</Link>
      <br />
      <br />
      <br />
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          if (businessID) {
            await deleteBusiness({
              variables: { _id: businessID },
            });

            history.push("/admin/businesses");
          }
        }}
      >
        <button>Ta bort företag</button>
      </form>
    </>
  );
};

export default Business;
