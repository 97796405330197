import React, { useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import GET_BATH_FROM_BUSINESS from '../../graphql/queries/GET_BATH_FROM_BUSINESS';

const ReportsBathSelector = ({ businessID, handleChange }) => {
  const { data: { getBathFromBusiness: businessBaths } = {}, bathLoading } = useQuery(GET_BATH_FROM_BUSINESS, { variables: { businessID } });

  useEffect(() => {
    if (businessBaths && businessBaths.length > 0) {
      handleChange({ target: {
        name: 'bathID',
        value: businessBaths[0]._id
      }});
    }
  }, [businessBaths]);

  if (bathLoading) return 'Laddar...';
  if (!businessBaths) return null;

  return (
    <>
      {businessBaths.length > 0 && (
        <label>Välj bad
          <select name="bathID" onChange={handleChange}>
            {businessBaths.map(b => <option key={b._id} value={b._id}>{b.name}</option>)}
          </select>
        </label>
      )}
    </>
  )
}

export default ReportsBathSelector;
