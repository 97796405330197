import React, { useState } from 'react';
import styled from '@emotion/styled';
import MeasurementInput from '../../components/Admin/MeasurementInput';
import Slots from '../../components/Admin/Slots';
import Group from '../../components/Admin/AdminGroup';
import { AdminButtonBasic, AdminButtonUpdate } from '../../components/Admin/AdminButtons';
import parseJSON from '../../helpers/parseJSON';
import SubstanceSelect from './SubstanceSelect';
import ForcedMaintenances, { ForcedMaintenanceWrapper } from './ForcedMaintenance';

const BathHandler = ({ bathID, updateBath, getAdminBathView, refetchBathView }) => {
  const bathCategoryID = getAdminBathView.bath.bathCategoryID;
  const { name, location, volume, factor, idealValue } = getAdminBathView.bath;
  const currentBathMeasurements = getAdminBathView.bath.measurementInputs;
  const currentBathMeasurementsData = parseJSON(currentBathMeasurements) || {};
  const currentBathInterval = getAdminBathView.bath.interval;
  const currentBathIntervalData = parseJSON(currentBathInterval) || {};
  const currentBathMaintenanceSubstances = getAdminBathView.bath.maintenanceSubstances;
  const currentBathMaintenanceSubstancesData = parseJSON(currentBathMaintenanceSubstances) || [];
  const currentForcedMaintenance = getAdminBathView.bath.forcedMaintenance;
  const currentForcedMaintenanceData = parseJSON(currentForcedMaintenance) || {};

  const [initialState, setInitialState] = useState({
    name,
    bathCategoryID,
    location,
    volume,
    factor,
    idealValue,
    interval: currentBathIntervalData,
    measurements: currentBathMeasurementsData,
    maintenanceSubstances: currentBathMaintenanceSubstancesData,
    forcedMaintenance: currentForcedMaintenanceData,
  });

  const bath = getAdminBathView.bath;
  const adminBathMeasurements = getAdminBathView.measurementInputs;
  const businessName = getAdminBathView.business.name;
  const allMeasurements = getAdminBathView.measurementInputs;
  const substances = getAdminBathView.substances;

  let currentBathCategoryName;

  const bathCategories = getAdminBathView.bathCategories;

  bathCategories.some(bc => {
    if (bath.bathCategoryID === bc._id) {
      currentBathCategoryName = bc.name;
      return true;
    } else {
      return false
    }
  });

  const handleBathState = e => {
    const inputTarget = e.target;
    const inputName = inputTarget.name;
    const inputValue = inputTarget.value.replace(',', '.');
    const inputType = inputTarget.type;

    setInitialState(is => {
      if (inputType === 'checkbox') {
        const substanceIndex = is.maintenanceSubstances.indexOf(inputName);

        if (substanceIndex !== -1 ) {
          if (!inputTarget.checked) {
            is.maintenanceSubstances.splice(substanceIndex, 1);
          }
        } else {
          if (inputTarget.checked) {
            is.maintenanceSubstances.push(inputName);
          }
        }

      } else {
        is[inputName] = inputValue;
      }

      return {
        ...is,
      }
    });
  }

  return (
    <>
      <Group>
        <form
        onSubmit={async e => {
          e.preventDefault();
          
          if (bathID) {
            const measurementInputs = initialState.measurements;
            const measurementInputsJSON = Object.keys(measurementInputs).length !== 0 ? JSON.stringify(measurementInputs) : null
            const { name, bathCategoryID, location, volume, factor, idealValue } = initialState;
            const interval = JSON.stringify(initialState.interval);
            const maintenanceSubstances = JSON.stringify(initialState.maintenanceSubstances);
            let forcedMaintenance = null;

            const formInputsTotal = e.target.length - 1;

            let forcedMaintenanceSubstance = null;
            let forcedMaintenanceAmount = null;

            for (let i = 0; i < formInputsTotal; i++) {
              const formInput = e.target[i];
              const inputId = formInput.getAttribute('id');

              if (inputId === 'forced-maintenance-substance') {
                forcedMaintenanceSubstance = formInput.value;
              }

              if (inputId === 'forced-maintenance-amount') {
                if (!isNaN(Number(formInput.value))) {
                  forcedMaintenanceAmount = formInput.value;
                }
              }
            }

            if (forcedMaintenanceSubstance && forcedMaintenanceAmount) {
              const now = new Date();
              const nowHours = now.getHours();
              const nowMinutes = now.getMinutes();
              const nowSeconds = now.getSeconds();
              const nowMonth = now.getMonth();
              const nowDate = now.getDate();
          
              const nowDateString = `${now.getFullYear()}-${nowMonth + 1 < 10 ? '0' : ''}${nowMonth + 1}-${nowDate < 10 ? '0' : ''}${nowDate}`;
              const nowTimeString = `${nowHours < 10 ? '0' : ''}${nowHours}:${`${nowMinutes < 10 ? '0' : ''}${nowMinutes}`}:${`${nowSeconds < 10 ? '0' : ''}${nowSeconds}`}`;  
              
              forcedMaintenance = JSON.stringify({
                date: nowDateString,
                time: nowTimeString,
                values: JSON.stringify({ substance: forcedMaintenanceSubstance, amount: forcedMaintenanceAmount })
              })
            }

            await updateBath({
              variables: { _id: bathID, name, bathCategoryID, location, volume, factor, idealValue, measurementInputs: measurementInputsJSON, interval, maintenanceSubstances, forcedMaintenance },
              refetchQueries: refetchBathView,
            });
          }
        }}
        >
          {bath.name && <h1>{bath.name}</h1>}
          {currentBathCategoryName && <h2>{currentBathCategoryName}</h2>}
          {bath.location && <h3>{bath.location}</h3>}
          <Group>
          <table>
          <tbody>
          <tr>
          <th><label>Namn</label></th>
          <td><input
            value={initialState.name || ''}
            type="text"
            name={'name'}
            placeholder={initialState.name || 'Namn'}
            onChange={handleBathState}
            /></td>

          </tr>
          <tr>
          <th><label>Plats</label></th>
          <td><input
            value={initialState.location || ''}
            type="text"
            name={'location'}
            placeholder={initialState.location || 'Plats'}
            onChange={handleBathState}
            /></td>
          </tr>
          <tr>
          <th><label>Volym</label></th>
          <td><input
            value={initialState.volume || ''}
            type="text"
            name={'volume'}
            placeholder={initialState.volume || 'Volym'}
            onChange={handleBathState}
            /></td>
          </tr>
          <tr>
          <th><label>Faktor</label></th>
          <td><input
            value={initialState.factor || ''}
            type="text"
            name={'factor'}
            placeholder={initialState.factor || 'Faktor'}
            onChange={handleBathState}
            /></td>
          </tr>
          <tr>
          <th><label>Idealvärde</label></th>
          <td><input
            value={initialState.idealValue || ''}
            type="text"
            name={'idealValue'}
            placeholder={initialState.idealValue || 'Idealvärde'}
            onChange={handleBathState}
            /></td>
            </tr>
            </tbody>
            </table>
            </Group>
            <Group>
          {bathCategories && bathCategories.length > 0 && (
            <div>
              <select key={initialState.bathCategoryID && `${initialState.bathCategoryID}select`} onChange={handleBathState} defaultValue={initialState.bathCategoryID}>
              <option value="">Ingen kategori</option>
                {bathCategories.map(c => <option value={c._id} key={c._id}>{c.name}</option> )}
              </select>
            </div>
          )}
          </Group>
          {allMeasurements && allMeasurements.length > 0 && (
            <Group>
            <table>
              <thead>
                <tr>
                  <th>Mätvärde</th>
                  <th>Min</th>
                  <th>Max</th>
                  <th>Ämne</th>
                </tr>
              </thead>
              <tbody>
                {allMeasurements.map(mi => {
                  return (
                    <MeasurementInput
                      key={mi._id}
                      id={mi._id}
                      adminName={mi.adminName}
                      name={mi.name}
                      initialState={initialState}
                      setInitialState={setInitialState}
                      adminBathMeasurements={adminBathMeasurements}
                      hasSubstance={mi.hasSubstance}
                      substances={substances}
                    />
                  )}
                )}
                </tbody>
              </table>
            </Group>
          )}
          <Group>
            <ForcedMaintenanceWrapper>
              <div>
                <h3>Tvinga underhåll</h3>
                <div>
                  <Group>
                    <SubstanceSelect substances={substances} htmlFor={'forced-maintenance-substance'}  useIdAsValue/>
                  </Group>
                  <Group>
                    <div><label htmlFor={'forced-maintenance-amount'}>Mängd</label></div>
                    <input id={'forced-maintenance-amount'} type="text" />
                  </Group>
                  <Group>
                    <AdminButtonBasic>Tvinga underhåll</AdminButtonBasic>
                  </Group>
                </div>
              </div>
              <div>
                <ForcedMaintenances bath={bath} substances={substances} updateBath={updateBath} refetchBathView={refetchBathView} />
              </div>
            </ForcedMaintenanceWrapper>
          </Group>
          <Slots
            initialState={initialState}
            setInitialState={setInitialState}
            type={'maintenance'}
            substances={substances}
          />
          <Slots
            initialState={initialState}
            setInitialState={setInitialState}
            type={'measurement'}
            substances={substances}
          />
          <Group>
          <h3>Tillåtna ämnen för valria underhåll</h3>
          {substances && substances.map(s => {
            return (
              <div key={s._id}>
                <label>
                  <input type="checkbox" name={s._id} onChange={handleBathState} checked={initialState.maintenanceSubstances.indexOf(s._id) !== -1 || false} />
                  {s.name}
                </label>
              </div>
            )
          })}
          </Group>
          <AdminButtonUpdate type="submit">Uppdatera bad</AdminButtonUpdate>
        </form>
      </Group>
    </>
  )
};

export default BathHandler;
