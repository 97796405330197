import gql from "graphql-tag";

export default gql`
  query {
    getSettings {
      customerServicePhone
      maintenanceDescription
    }
  }
`;
