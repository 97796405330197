import styled from '@emotion/styled';

const Ul = styled('ul')`
  ${({ theme }) => theme.misc.adminBox}
  margin-bottom: 1rem;
  
  > li {
    font-weight: 700;
    border-top: 1px solid ${({ theme }) => theme.colors.greyLight};
    padding: .5em 1.25em;

    &:first-of-type {
      border-top: 0;
    }

    &:hover {
      background: ${({ theme }) => theme.colors.greyLight};
    }
  }
`;

export default Ul
