import React from "react";
import { ThemeProvider } from "emotion-theming";
import { Global } from "@emotion/core";

const deviceSizes = {
  small: "480px",
  smallUp: "481px",
  medium: "767px",
  mediumUp: "768px",
  large: "1024px",
  largeUp: "1025px",
};

const spaceInt = [4, 8, 16, 24, 32, 40, 48, 56, 64];

const space = [
  `${spaceInt[0]}px`,
  `${spaceInt[1]}px`,
  `${spaceInt[2]}px`,
  `${spaceInt[3]}px`,
  `${spaceInt[4]}px`,
  `${spaceInt[5]}px`,
  `${spaceInt[6]}px`,
  `${spaceInt[7]}px`,
  `${spaceInt[8]}px`,
];

const colors = {
  black: "#000",
  blue: "#C0D5DE",
  blueLight: "#F4F9FC",
  blueMedium: "#d7e5f0",
  blueDark: "#1F5065",
  blueDarkDarken: "#114256",
  blueCta: "#1864CC",
  green: "#2CAF72",
  greenLight: "#D5F0E4",
  greenDark: "#0C5232",
  grey: "#D7DCE0",
  greyLight: "#f7f7f7",
  red: "#CB1941",
  redLight: "#FFF5F9",
  redMedium: "#FFEBF1",
  redDark: "#762638",
  white: "#FFF",
};

colors.primary = colors.blueDark;

const sizes = {
  headerBarHeight: "56px",
};

const misc = {
  adminButtonShared: `
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${colors.white};
    font-size: 1rem;
    padding: .25em .5em;

    > svg {
      width: 1rem;
      height: 1rem;
      margin-left: 1rem;
    }
  `,
  adminInputBorderRadius: "4px",
  borderRadius: "8px",
  transition: ".2s",
  adminShadow: "box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.10)",
};

misc.adminBox = `
  ${misc.adminShadow};
  border-radius: 4px;
  background: #fff;
`;

const mediaQuery = {
  sd: `@media only screen and (max-width: ${deviceSizes.small})`,
  su: `@media only screen and (min-width: ${deviceSizes.smallUp})`,
  md: `@media only screen and (max-width: ${deviceSizes.medium})`,
  mu: `@media only screen and (min-width: ${deviceSizes.mediumUp})`,
  ld: `@media only screen and (max-width: ${deviceSizes.large})`,
  lu: `@media only screen and (min-width: ${deviceSizes.largeUp})`,
};

const elements = {
  container: `
    width: 100%;
    max-width: ${sizes.maxWidthM};
    margin: 0 auto;
    padding: 0 ${sizes.edgeMargin};

    ${mediaQuery.md} {
      padding: 0 calc(${sizes.edgeMargin} / 2);
    }
  `,
};

export const theme = {
  elements,
  mediaQuery,
  colors,
  space,
  spaceInt,
  sizes,
  misc,
};

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

const GlobalStyles = () =>
  React.createElement(
    Global,
    {
      styles: `
      html, body {
        height: 100%;
      }
      body {
        margin: 0;
        font-family: arial;
        font-size: 16px;
        line-height: 1.5em;
        background-color: ${theme.colors.blueLight};
        color: ${theme.colors.blueDark};
      }

      #root {
        height: 100%;
        > div {
          height: 100%;
        }
      }

      h1, h2 {
        color: ${theme.colors.blueDark};
        font-size: 1.5rem;
      }

      a {
        color: inherit;
        text-decoration: none;
      }

      ul {
        padding: 0;
        margin: 0;
      }

      li {
        list-style: none;
      }

      * apply a natural box layout model to all elements, but allowing components
      * to change */
       html {
         box-sizing: border-box;
       }
       *,
       *:before,
       *:after {
         box-sizing: inherit;
       }
    `,
    },
    null
  );

export { Theme, GlobalStyles };
