module.exports = jsonData => {
  if (jsonData) {
    try {
      const parsed = JSON.parse(jsonData);
      return parsed;
    } catch(e) {
      console.log('invalid JSON');
      return false;
    }
  }
}
