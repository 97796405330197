import React, {useState, useEffect, useContext, Fragment} from "react";
import { AppContext } from '../../AppContext';
import { useQuery, useMutation } from '@apollo/react-hooks';
import GET_BATH_REPORTS from '../../graphql/queries/GET_BATH_REPORTS';
import parseJSON from '../../helpers/parseJSON';
import styled from '@emotion/styled';
import posed, { PoseGroup } from 'react-pose';
import ModalReport, { ModalWrapper } from '../ViewUser/ModalReport'
import GetReportData from './GetReportData';
import ADD_REPORT_MEASUREMENT from '../../graphql/mutations/ADD_REPORT_MEASUREMENT';
import GET_USER_VIEW from '../../graphql/queries/GET_USER_VIEW';
import {ReactComponent as IconHistory} from '../../svg/IconHistory.svg'
import {ReactComponent as IconErase} from '../../svg/IconErase.svg'
import {ReactComponent as IconCreate} from '../../svg/IconCreate.svg'
import {ReactComponent as IconCross} from '../../svg/IconCross.svg'
import {ReactComponent as IconPen} from '../../svg/IconPen.svg'
import {ReactComponent as IconTemperature} from '../../svg/IconTemperature.svg'
import {ReactComponent as IconKoncentration} from '../../svg/IconKoncentration.svg'

const PosedModalWrapper = styled(posed.div({
  preEnter: {
    y: 400,
  },
  enter: {
    y: 0,
    delay: 200,
    transition: {
      y: { type: 'spring', stiffness: 880, damping: 46 },
      default: { duration: 500 }
    }
  },
  exit: {
    y: 400,
    delay: 200,
    transition: {
      y: { ease: 'easeOut', duration: 600 }
    }
  },
  historyEnter: {
    y: 0,
    delay: 200,
    transition: {
      y: { ease: 'easeOut', duration: 400 }
    }
  },
}))`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
`;

const Cover = styled(posed.div({
  enter: {
    opacity: 1,
    transition: { duration: 100 }
  },
  exit: {
    delay: 600,
    opacity: 0,
    transition: { duration: 100 }
  },
}))`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1;
`;

const StyledIconTemperature = styled(IconTemperature)`
  width: 12px;
  height: 26px;
  fill: none;
  stroke: ${({theme}) => theme.colors.blueDark};
  stroke-width: 2px;
  margin-bottom: ${({theme}) => theme.space[1]};
  opacity: .6;
`;

const StyledIconKoncentration = styled(IconKoncentration)`
  width: 20px;
  height: 20px;
  fill: none;
  stroke: ${({theme}) => theme.colors.blueDark};
  stroke-width: 2px;
  margin-bottom: ${({theme}) => theme.space[1]};
  opacity: .6;
`;

const CrossBadge = styled('div')`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: ${({theme}) => theme.space[4]};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: ${({theme}) => theme.colors.blueDark};

  ${({isDisabled}) => isDisabled && `
    opacity: .2;
    pointer-events: none;
  `}
`;

const StyledIconCross = styled(IconCross)`
  width: 10px;
  height: 10px;
  stroke: ${({theme}) => theme.colors.white};
  stroke-width: 2px;
`;

const StyledIconPen = styled(IconPen)`
  fill: none;
  stroke: ${({theme}) => theme.colors.blueDark};
  stroke-width: 2px;
  width: 12px;
  height: 12px;
`;

const PenBadge = styled('div')`
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: ${({theme}) => theme.colors.blue};
`;

const StyledIconCreate = styled(IconCreate)`
  width: 24px;
  height: 24px;
  margin-left: ${({theme}) => theme.space[2]};
`;

const StyledIconHistory = styled(IconHistory)`
  width: 18px;
  height: 16px;
  margin-left: ${({theme}) => theme.space[1]};
`;

const StyledIconErase = styled(IconErase)`
  width: 32px;
  height: 24px;
`;

const BoardWrapper = styled('div')`
  display: flex;
  width: 100%;
  height: calc(100% - ${({theme}) => theme.sizes.headerBarHeight});
  background: ${({theme}) => theme.colors.grey};

  > h1 {
    text-align: center;
    text-transform: uppercase;
    padding-top: ${({theme}) => theme.space[4]};
    width: 100%;
  }
`

const Header = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 0 1 30%;
  margin: ${({theme}) => theme.space[2]} ${({theme}) => theme.space[4]} ${({theme}) => theme.space[4]};
`;

const InfoSection = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 100%;

  span {
    font-size: .85rem;
    font-weight: 600;
    text-transform: uppercase;
  }
`;

const Name = styled('h1')`
  text-transform: uppercase;
  margin: 0;
  text-align: center;
  font-size: 2.4rem;
`;

const ShowHistory = styled('span')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 138px;
  height: 42px;
  border: 1px solid ${({theme}) => theme.colors.blueDark};
  border-radius: ${({theme}) => theme.misc.borderRadius};
  font-weight: 600;
  text-transform: uppercase;
`;

const Measuerements = styled('div')`
  display: flex;
  flex-wrap: wrap;
  flex: 0 1 33%;
  background: ${({theme}) => theme.colors.blueMedium};
  border-bottom: 1px solid ${({theme}) => theme.colors.blue};
  overflow: hidden;
`;

const Description = styled('div')`
  flex: 1 1 auto;
  background: ${({theme}) => theme.colors.white};
  padding: ${({theme}) => theme.space[3]};

  h2 {
    margin: 0;
    margin-bottom: ${({theme}) => theme.space[2]};
    text-transform: capitalize;
  }
`;

const InfoColumn = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 0 1 45%;
`;

const InputHeader = styled('div')`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 1 30%;
  padding: ${({theme}) => theme.space[2]} ${({theme}) => theme.space[4]} ${({theme}) => theme.space[4]};
  background: ${({theme}) => theme.colors.blueCta};

  h2 {
    position: absolute;
    top: 50%;
    transform: translateY(calc(-50% - 36% - 44px));
    margin: 0;
    color: ${({theme}) => theme.colors.white};
    font-size: 2.2rem;
  }
`

const Input = styled('span')`
  position: relative;
  display: flex;
  align-items: center;
  position: absolute;
  width: calc(100% - 64px);
  height: 36%;
  font-size: 1.4rem;
  font-weight: 600;
  bottom: 0;
  padding: 0 32px;
  background: ${({theme}) => theme.colors.white};
  color: ${({theme}) => theme.colors.blueDark};
  box-shadow: 0px -4px 14px 2px rgba(0,0,0,0.1);
  z-index: 1;
`;

const InputColumn = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 0 1 55%;
  overflow: hidden;
`;

const InputButton = styled(posed.span({
  pressed: {
    background: 'rgba(69, 117, 205, .08)'
  },
  notPressed: {
    background: 'rgba(69, 117, 205, 0)'
  }
}))`
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% / 3 - 1px);
  border: 1px solid ${({theme}) => theme.colors.blue};;
  border-top: none;
  border-left:none;
  font-size: 1.6rem;
  font-weight: 600;
  cursor: pointer;

  &:nth-of-type(3n + 3) {
    border-right: none;
  }

`;

const InputPanel = styled('div')`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
  background: ${({theme}) => theme.colors.blueLight};

  ${({isDisabled}) => isDisabled && `
    span {
      opacity: .2;
      pointer-events: none;
    }
  `}
`;

const ActionButton = styled(posed.span({
  pressed: {
    opacity: .6
  },
  notPressed: {
    opacity: 1
  }
}))`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 8%;
  min-height: 72px;
  width: 100%;
  background: ${({theme}) => theme.colors.blueCta};
  color: ${({theme}) => theme.colors.white};
  text-transform: uppercase;
  font-weight: 600;

  ${({theme, registerReport}) => registerReport && `
    background: ${theme.colors.green};
  `}

  span {
    display: flex;
    align-items: center;
  }
`;

const MeasuerementsButtonLarge = styled('div')`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100% / 2 - .5px);
  height: 50%;
  border: 1px solid ${({theme}) => theme.colors.blue};;
  border-top: none;
  border-left:none;
  font-size: 1.6rem;
  font-weight: 600;
  cursor: pointer;

  &:nth-of-type(2n + 2) {
    border-right: none;
  }

  h5 {
    font-size: .85rem;
    text-transform: uppercase;
    opacity: .6;
    margin: 0;
  }

  ${({theme, active}) => active && `
    background: ${theme.colors.blueCta};
    color: ${theme.colors.white};

    h5 {
      opacity: 1;
    }

    > div:first-of-type {
      background: ${theme.colors.white};
    }

    > svg:first-of-type {
      stroke: ${theme.colors.white};
      opacity: 1;
    }
  `}

  ${({theme, hasValue, active}) => hasValue && !active && `
    background: ${theme.colors.white};
    color: ${theme.colors.blueDark};

    h5 {
      opacity: 1;
    }

    > svg:first-of-type {
      stroke: ${theme.colors.blueDark};
      opacity: 1;
    }
  `}

  ${({isDisabled}) => isDisabled && `
    opacity: .4;
    pointer-events: none;
  `}
`;

const MeasuerementsButton = styled('div')`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100% / 4 - 0.8px);
  height: 50%;
  border: 1px solid ${({theme}) => theme.colors.blue};;
  border-top: none;
  border-left:none;
  font-size: 1.6rem;
  font-weight: 600;
  cursor: pointer;

  &:nth-of-type(6n + 6) {
    border-right: none;
  }

  h5 {
    font-size: .75rem;
    text-transform: uppercase;
    opacity: .6;
    margin: 0;
  }

  ${({theme, active}) => active && `
    background: ${theme.colors.blueCta};
    color: ${theme.colors.white};

    h5 {
      opacity: 1;
    }

    > div:first-of-type {
      background: ${theme.colors.white};
    }

    > svg:first-of-type {
      stroke: ${theme.colors.white};
    }
  `}

  ${({theme, hasValue, active}) => hasValue && !active && `
    background: ${theme.colors.white};
    color: ${theme.colors.blueDark};

    h5 {
      opacity: 1;
    }

    > svg:first-of-type {
      stroke: ${theme.colors.blueDark};
    }
  `}

  ${({isDisabled}) => isDisabled && `
    opacity: .4;
    pointer-events: none;
  `}
`;

const MeasuerementValue = styled('div')`
  font-size: 1rem;
  padding-top: 4px;
`;

export const handleConcentrationValue = (inputValue, measurementId, factor) => {
  let returnValue = inputValue;

  // check if koncentration id
  if (measurementId === '5dea3c46924a60a46912850f') {
    returnValue = Number(factor) * Number(inputValue);
  }

  return returnValue;
}

const MeasureBoard = ({ bath, userView }) => {
  const coverRef = React.createRef();
  
  const [addReportMeasurement] = useMutation(ADD_REPORT_MEASUREMENT);
   
  const [modalObject, setModalObject] = React.useState({});
  const [specialChar, setSpecialChar] = React.useState(false);
  const [repportValues, setRepportValues] = React.useState([]);

  const [buttonPressed, setButtonPressed] = React.useState(false);

  const inputMeasurementsObject = parseJSON(bath.measurementInputs);

  const { appState } = useContext(AppContext);
  const { appData } = appState;

  let inputMeasurementsMap = {};

  appData && appData.measurementInputs.forEach(m => {inputMeasurementsMap[m._id] = m });

  let inputButtons = [];
  let initialInputValues = {
    inputValues: {},
    activeField: '',
    position: 0,
    createReport: false,
    changedValue: false,
    editMode: false,
  };

  for (const property in inputMeasurementsObject) {
    if ((inputMeasurementsObject[property].active === 'active' && !inputMeasurementsObject[property].substance) || (inputMeasurementsObject[property].active === 'active' && inputMeasurementsObject[property].substance && bath.volume && bath.factor && bath.idealValue)) {
      inputButtons.push({
        id: property,
        order: inputMeasurementsMap[property].order,
        name: inputMeasurementsMap[property].name,
        icon: inputMeasurementsMap[property].icon,
        large: false,
      });
    }
  }

  inputButtons.sort((a, b) => {
    return a.order - b.order;
  });

  let position = 0;

  inputButtons.forEach(button => {
    position ++
    button.order = position;
    button.large = (position === 1 || position === 2) ? true : false;

    if (position === 1) {
      initialInputValues.activeField = button.id;
    }

    initialInputValues.inputValues[button.id] = {
      value: '',
      active: false,
      required: inputMeasurementsObject[button.id].substance,
      status: 'ok'
    };
  })

  const [inputMeasurements, setInputMeasurements] = useState(initialInputValues);

  const setActiveMeasurement = (activeMeasurement, order) => {

    if (!inputMeasurements.inputValues[inputButtons[order - 1].id].active) return;

    setInputMeasurements(im => {
      im.position = order - 1;
      im.activeField = activeMeasurement;

      if (im.inputValues[activeMeasurement].active) {
        im.editMode = true;
      }

      return {...im}
    });
  };

  const checkErrors = (id = false) => {
    let modalObj = {};
    let repportStatus = '';

    // Use helper function to handle measurementChecks
    // validateMeasurementInput(inputMeasurements, inputMeasurementsObject, inputMeasurementsMap, bath, {
    //   badMeasurementValueCallback(inputMeasurements, inputMeasurementsObject, calculatedInput, property, inputMin, inputMax) {
    //     if (repportStatus !== 'errorCritical' && repportStatus !== 'ok') {

    //       // Use to break flow if error on certain id
    //       if (id === property) {
    //         return true;
    //       }

    //       let valueTxt = '';

    //       if (calculatedInput < inputMin) {
    //         valueTxt = 'LÅG';            
    //       } else if (calculatedInput > inputMax) {
    //         valueTxt = 'HÖG';
    //       }
          
    //       modalObj[property] = {
    //         type: inputMeasurements.inputValues[property].required && inputMeasurementsObject[property].substance ? 'errorCritical' : 'error',
    //         value: inputMeasurements.inputValues[property].value,
    //         substance: inputMeasurementsObject[property].substance,
    //         valueTxt: valueTxt,
    //         name: inputMeasurementsMap[property] && inputMeasurementsMap[property].name,
    //         message: inputMeasurementsMap[property] && inputMeasurementsMap[property].errorMessage,
    //         phone: appData && appData.information.customerServicePhone,
    //         buttonTxt: inputMeasurements.inputValues[property].required && inputMeasurementsObject[property].substance ? 'Utför underhåll' : 'Stäng',
    //       };
    //     }

    //     repportStatus = inputMeasurements.inputValues[property].required && inputMeasurementsObject[property].substance ? 'errorCritical' : 'error';      
    //   },
    //   validMeasurementWithSubstanceCallback(inputMeasurements, property) {
    //     inputMeasurements.inputValues[property].status = 'ok';

    //     modalObj[property] = {
    //       type: 'ok',
    //       name: 'Mätning OK',
    //       message: 'En rapport har skapats med följande mätvärden.',
    //       buttonTxt: 'Stäng'
    //     };

    //     repportStatus = 'ok'
    //   },
    //   validMeasurementDefaultCallback(inputMeasurements, property) {
    //     inputMeasurements.inputValues[property].status = 'ok';
    //   }
    // })

    // Old code - Use this to replace validateMeasurementInput();
    for (const property in inputMeasurements.inputValues) {
      
      const inputValue = Number(inputMeasurements.inputValues[property].value);
      let calculatedInput = inputValue;

      // Add check to control (koncentation * faktor)
      calculatedInput = handleConcentrationValue(inputValue, inputMeasurementsMap[property]._id, bath.factor);
      // if (inputMeasurementsMap[property].name === 'koncentration') {
      //   calculatedInput = Number(bath.factor) * inputValue;
      // }
      
      const inputMin = Number(inputMeasurementsObject[property].inputMin);
      const inputMax = Number(inputMeasurementsObject[property].inputMax);

      if (calculatedInput < inputMin || calculatedInput > inputMax) {
        if (repportStatus !== 'errorCritical' && repportStatus !== 'ok') {

          // Use to break flow if error on certain id
          if (id === property) {
            return true;
          }

          let valueTxt = '';

          if (calculatedInput < inputMin) {
            valueTxt = 'LÅG';

            inputMeasurements.inputValues[property].status = 'error';
            
          } else if (calculatedInput > inputMax) {
            valueTxt = 'HÖG';

            inputMeasurements.inputValues[property].status = 'ok'
          }
          
          modalObj[property] = {
            type: inputMeasurements.inputValues[property].required && inputMeasurementsObject[property].substance ? 'errorCritical' : 'error',
            value: inputMeasurements.inputValues[property].value,
            substance: inputMeasurementsObject[property].substance,
            valueTxt: valueTxt,
            name: inputMeasurementsMap[property] && inputMeasurementsMap[property].name,
            message: inputMeasurementsMap[property] && inputMeasurementsMap[property].errorMessage,
            phone: appData && appData.information.customerServicePhone,
            buttonTxt: inputMeasurements.inputValues[property].required && inputMeasurementsObject[property].substance ? 'Utför underhåll' : 'Stäng',
          };
        }

        repportStatus = inputMeasurements.inputValues[property].required && inputMeasurementsObject[property].substance ? 'errorCritical' : 'error';

      } else if (inputMeasurements.inputValues[property].required && inputMeasurementsObject[property].substance) {
        inputMeasurements.inputValues[property].status = 'ok';

        modalObj[property] = {
          type: 'ok',
          name: 'Mätning OK',
          message: 'En rapport har skapats med följande mätvärden.',
          buttonTxt: 'Stäng'
        };

        repportStatus = 'ok'
      } else {
        inputMeasurements.inputValues[property].status = 'ok';
      }
    }

    if (!Object.keys(modalObj).length) {
      modalObj[0] = {
        type: 'ok',
        name: 'Mätning OK',
        message: 'En rapport har skapats med följande mätvärden.',
        buttonTxt: 'Stäng'
      };
    }

    setTimeout(() => {
      setModalObject(modalObj);
    }, 150)

    return repportStatus;
  }

  const showHistory = name => {
    let modalObject = {}
    modalObject[0] = {
      type: 'history',
      name: name,
    }

    setModalObject(modalObject);
  }

  const createReport = async () => {
    const now = new Date();
    const nowHours = now.getHours();
    const nowMinutes = now.getMinutes();
    const nowSeconds = now.getSeconds();
    const nowMonth = now.getMonth();
    const nowDate = now.getDate();
    const nowDateString = `${now.getFullYear()}-${nowMonth + 1 < 10 ? '0' : ''}${nowMonth + 1}-${nowDate < 10 ? '0' : ''}${nowDate}`;
    const nowTimeString = `${nowHours < 10 ? '0' : ''}${nowHours}:${`${nowMinutes < 10 ? '0' : ''}${nowMinutes}`}:${`${nowSeconds < 10 ? '0' : ''}${nowSeconds}`}`;

    const status = checkErrors();

    let values = [];


    Object.keys(inputMeasurements.inputValues).map(key => {
      values.push({
        id: key,
        value: inputMeasurements.inputValues[key].value,
        status: inputMeasurements.inputValues[key].status,
      })
    })

    const stringifiedValues = JSON.stringify(values);
    await addReportMeasurement({
     variables: { bathID: bath._id, date: nowDateString, time: nowTimeString, status: status, values: stringifiedValues },
     refetchQueries: [{ query: GET_USER_VIEW }],
    });

    setRepportValues(values);
  }

  const goToNext = () => {
    setButtonPressed('goToNext');

    setTimeout(() => {
      setButtonPressed(false);
    }, 100)

    if (inputMeasurements.createReport && !inputMeasurements.changedValue && !inputMeasurements.editMode) {
      // Exit editmode
      console.log('Create report');
      console.log(inputMeasurements);

      createReport();

      return;
    }

    if (inputMeasurements.createReport && inputMeasurements.changedValue) {
      // Exit editmode
      console.log('Exit editmode when edited');

      setInputMeasurements(im => {
        im.changedValue = false;
        im.editMode = false;
        im.inputValues[inputButtons[im.position].id].active = true;

        return {...im}
      });

      return;
    }

    if (inputMeasurements.position + 1 < inputButtons.length && !inputMeasurements.createReport) {
      // Go to next step
      console.log('Go to next step');

      setInputMeasurements(im => {
        let position = im.position + 1;

        im.position = position;
        im.activeField = inputButtons[position].id;
        im.inputValues[inputButtons[position - 1].id].active = true;
        im.editMode = false;
        im.changedValue = false;

        if (!im.inputValues[inputButtons[position].id].active && im.inputValues[inputButtons[position].id].value.length) {
          im.inputValues[inputButtons[position].id].value = '';
        }

        setSpecialChar(false);

        return {...im}
      });
    } else {
      // Enable create report
      console.log('Enable create report');

      setInputMeasurements(im => {
        im.inputValues[inputButtons[im.position].id].active = true;
        im.editMode = false;
        im.createReport = true;

        return {...im}
      });
    }
  }

  const addValue = (value, special) => {
    const specialCharFound = /[.]/g


    if (inputMeasurements.inputValues[inputMeasurements.activeField].value.length > 8) return;
    if ((special && specialChar) || (!inputMeasurements.inputValues[inputMeasurements.activeField].value.length && special)) return;
    if (value === '.' && inputMeasurements.inputValues[inputMeasurements.activeField].value.match(specialCharFound)) return;

    setButtonPressed(value);

    setTimeout(() => {
      setButtonPressed(false);
    }, 100)

    if (special) {
      setSpecialChar(true);
    }

    setInputMeasurements(im => {
      im.inputValues[im.activeField].value += value;
      if (im.inputValues[im.activeField].active) {
        im.changedValue = true;
      }

      return {...im}
    });
  }

  const erase = () => {
    setButtonPressed('erase');

    setTimeout(() => {
      setButtonPressed(false);
    }, 100)
    if (inputMeasurements.inputValues[inputMeasurements.activeField].value.length) {
      if (inputMeasurements.inputValues[inputMeasurements.activeField].value.charAt(inputMeasurements.inputValues[inputMeasurements.activeField].value.length - 1) === '.') {
        setSpecialChar(false);
      }

      setInputMeasurements(im => {
        im.inputValues[im.activeField].value = im.inputValues[im.activeField].value.slice(0,-1);
        if (im.inputValues[im.activeField].active) {
          im.changedValue = true;
        }

        return {...im}
      });
    }
  }

  const eraseActive = () => {
    setInputMeasurements(im => {
      im.inputValues[im.activeField].value = '';

      return {...im}
    });
  }

  useEffect(() => {
    return () => {
      setInputMeasurements({});
    }
  }, []);

  return inputMeasurements.inputValues[inputMeasurements.activeField] ? (
    <BoardWrapper>
      <InfoColumn>
        <Header>
          <InfoSection>
            <span>{bath.category}</span>
            <span>{bath.area}</span>
          </InfoSection>
          <Name>{bath.name}</Name>
          <ShowHistory onClick={() => {showHistory(inputMeasurementsMap[inputMeasurements.activeField].name)}} >
            Historik <StyledIconHistory />
          </ShowHistory>
        </Header>
        <Measuerements>
          {inputButtons.map((inputButton, index) => {
            const Wrapper = inputButton.large ? MeasuerementsButtonLarge : MeasuerementsButton;

            return (
              <Wrapper className={`${inputButton.required} ${bath.idealValue}`}  key={index} onClick={() => {setActiveMeasurement(inputButton.id, inputButton.order)}} active={(!inputMeasurements.createReport && inputButton.id === inputMeasurements.activeField) || (inputMeasurements.createReport && inputButton.id === inputMeasurements.activeField && inputMeasurements.editMode)} hasValue={inputMeasurements.inputValues[inputButton.id].active} isDisabled={inputMeasurements.changedValue && inputButton.id !== inputMeasurements.activeField} >
                {inputMeasurements.inputValues[inputButton.id].active && (
                  <PenBadge><StyledIconPen /></PenBadge>
                )}
                {inputButton.icon && inputButton.icon === 'temperatur' && (
                  <StyledIconTemperature />
                )}
                {inputButton.icon && inputButton.icon === 'koncentration' && (
                  <StyledIconKoncentration />
                )}
                <h5>{inputButton.name}</h5>
                {inputMeasurements.inputValues[inputButton.id].active && (
                  <MeasuerementValue>{inputMeasurements.inputValues[inputButton.id].value.length ? inputMeasurements.inputValues[inputButton.id].value : '-'}</MeasuerementValue>
                )}
              </Wrapper>
            )}
          )}
        </Measuerements>
        <Description>
          <h2>{inputMeasurementsMap[inputMeasurements.activeField].name}</h2>
          {inputMeasurementsMap[inputMeasurements.activeField].description}
        </Description>
      </InfoColumn>
      <InputColumn>
        <InputHeader>
          <h2>{inputMeasurements.createReport && !inputMeasurements.editMode ? 'Värden ifyllda' : `Ange ${inputMeasurementsMap[inputMeasurements.activeField].name}`}</h2>
          <Input>
            {inputMeasurements.createReport && !inputMeasurements.editMode ? '' : inputMeasurements.inputValues[inputMeasurements.activeField].value}
            
            {inputMeasurements.inputValues[inputMeasurements.activeField].value.length ? (
              <CrossBadge isDisabled={inputMeasurements.createReport && !inputMeasurements.editMode} onClick={() => eraseActive()} ><StyledIconCross /></CrossBadge>
            ): null}
          </Input>
        </InputHeader>

        <InputPanel isDisabled={inputMeasurements.createReport && !inputMeasurements.editMode}>
          <InputButton pose={buttonPressed === '1' ? 'pressed' : 'notPressed'} onClick={() =>{addValue('1')}}>1</InputButton>
          <InputButton pose={buttonPressed === '2' ? 'pressed' : 'notPressed'} onClick={() =>{addValue('2')}}>2</InputButton>
          <InputButton pose={buttonPressed === '3' ? 'pressed' : 'notPressed'} onClick={() =>{addValue('3')}}>3</InputButton>

          <InputButton pose={buttonPressed === '4' ? 'pressed' : 'notPressed'} onClick={() =>{addValue('4')}}>4</InputButton>
          <InputButton pose={buttonPressed === '5' ? 'pressed' : 'notPressed'} onClick={() =>{addValue('5')}}>5</InputButton>
          <InputButton pose={buttonPressed === '6' ? 'pressed' : 'notPressed'} onClick={() =>{addValue('6')}}>6</InputButton>

          <InputButton pose={buttonPressed === '7' ? 'pressed' : 'notPressed'} onClick={() =>{addValue('7')}}>7</InputButton>
          <InputButton pose={buttonPressed === '8' ? 'pressed' : 'notPressed'} onClick={() =>{addValue('8')}}>8</InputButton>
          <InputButton pose={buttonPressed === '9' ? 'pressed' : 'notPressed'} onClick={() =>{addValue('9')}}>9</InputButton>

          <InputButton pose={buttonPressed === '.' ? 'pressed' : 'notPressed'} onClick={() =>{addValue('.', true)}}>.</InputButton>
          <InputButton pose={buttonPressed === '0' ? 'pressed' : 'notPressed'} onClick={() =>{addValue('0')}}>0</InputButton>
          <InputButton pose={buttonPressed === 'erase' ? 'pressed' : 'notPressed'} onClick={() => erase()}><StyledIconErase /></InputButton>
        </InputPanel>

        <ActionButton pose={buttonPressed === 'goToNext' ? 'pressed' : 'notPressed'} onClick={() => {goToNext()}} registerReport={inputMeasurements.createReport  && !inputMeasurements.editMode} >
          {inputMeasurements.createReport && !inputMeasurements.editMode && (
            <span>Skapa rapport <StyledIconCreate /></span>
          )}

          {!inputMeasurements.editMode && !inputMeasurements.createReport && (
            <span>Ange värde</span>
          )}

          {inputMeasurements.editMode && (
            <span>Uppdatera värde</span>
          )}
        </ActionButton>
      </InputColumn>

      <PoseGroup preEnterPose='preEnter' enterPose={(modalObject[0] && modalObject[0].type === 'history') ? 'historyEnter' : 'enter'} exitPose={(modalObject[0] && modalObject[0].type === 'history') === 'history' ? 'historyExit' : 'exit'}>
        {Object.keys(modalObject).length ? Object.keys(modalObject).reverse().map((id, index) => (
          <PosedModalWrapper key={index}>
            <ModalReport
              key={index}
              type={modalObject[id].type}
              id={id}
              substance={modalObject[id].substance}
              valueTxt={modalObject[id].valueTxt}
              buttonTxt={modalObject[id].buttonTxt}
              name={modalObject[id].name}
              message={modalObject[id].message}
              phone={modalObject[id].phone}
              userView={userView}
              bath={bath}
              setModalObject={setModalObject}
              repportValues={repportValues}
              coverRef={coverRef}
            >
              <GetReportData bathID={bath._id} offset={0} limit={10} type='measurement' />
            </ModalReport>
          </PosedModalWrapper>
        )): null}

        {Object.keys(modalObject).length ? (
          <Cover key='Cover' ref={coverRef} />
        ): null}
      </PoseGroup>
    </BoardWrapper>
  ): <BoardWrapper><h1>Inga aktiva värden hittas</h1></BoardWrapper>;
}

export default MeasureBoard