import React from 'react';

const SubstanceSelect = ({ substances, htmlFor, useIdAsValue = false }) => {
	return (
		<>
			{substances.length > 0
				? 
				<>
					<div><label htmlFor={htmlFor}>Ämne</label></div>
					<select
						type="text" 
						id={htmlFor}
						required
					>
						{substances.map((substance, i) => <option key={i} value={useIdAsValue ? substance._id : substance.name}>{substance.name}</option>)}
					</select>
				</>
				: <span>Inga ämnen tillgängliga</span>}
		</>
	);
}

export default SubstanceSelect;