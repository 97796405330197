import React, { useEffect, useReducer, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import GET_USER_VIEW from "../../graphql/queries/GET_USER_VIEW";
import styled from "@emotion/styled";
import posed, { PoseGroup } from "react-pose";
import slugifyURL from "../../helpers/slugifyURL";
import HeaderBar from "../../components/Blocks/HeaderBar";
import BathGrid from "../../components/Blocks/BathGrid";
import ModalView from "../../components/ViewUser/ModalView";

const PosedDiv = styled(
  posed.div({
    enter: {
      x: 0,
      transition: {
        x: { ease: "easeOut", duration: 500 },
      },
    },
    exit: {
      x: "100%",
      delay: 800,
      transition: {
        x: { ease: "easeOut", duration: 600 },
      },
    },
    exitFast: {
      x: "100%",
      delay: 300,
      transition: {
        x: { ease: "easeOut", duration: 600 },
      },
    },
  })
)`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;

  > div {
    box-shadow: 0 0 44px rgba(0, 0, 0, 0.4);
  }
`;

const LoadingScreen = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${({ theme }) => theme.colors.white};
`;

export default () => {
  const { data, error, loading, refetch } = useQuery(GET_USER_VIEW, {
    errorPolicy: "all",
  });
  const history = useHistory();
  const location = useLocation();

  if (error) {
    if (error.graphQLErrors && error.graphQLErrors.length > 0) {
      return error.graphQLErrors.map((error) => (
        <div key={error.message}>{error.message}</div>
      ));
    } else {
      return <div>An error occured. Please contact IKP</div>;
    }
  }

  useEffect(() => {
    if (data && data.getUserView && data.getUserView.business) {
      const slug = slugifyURL(data.getUserView.business.name);

      if (location.pathname !== `/user/${slug}`) {
        history.push(`/user/${slug}`);
      }
    }
  }, [data, history, location]);

  const bathCategories = data && data.getUserView.bathCategories;
  const business = data && data.getUserView.business.name;

  // REUDUCERS
  const initialState = {
    type: "",
    bath: null,
    status: "ok",
    repportValues: null,
    intervalData: null,
    showModalView: false,
    customCallback: null
  };

  const reducer = (state, action) => {
    switch (action.actionType) {
      case "SHOW_MODAL":
        return {
          ...state,
          type: action.type,
          bath: action.bath,
          status: action.status,
          repportValues: action.repportValues,
          intervalData: action.intervalData,
          showModalView: true,
          customCallback: state.customCallback
        };

      case "HIDE_MODAL":
        return {
          ...state,
          type: action.type,
          bath: null,
          status: null,
          repportValues: null,
          intervalData: null,
          showModalView: false,
          customCallback: null
        };

      default:
        return {
          ...state,
        };
    }
  };

  const [state, userView] = useReducer(reducer, initialState);
  const [rerender, setRerender] = useState(0);

  const rerenderFunction = () => {
    setRerender(rerender + 1);
  };

  let timeController;

  const timeOutController = (intervalData, compareThisTime) => {
    refetch();

    let timeOutTime = false;

    console.log("INTERVAL DATA: ", intervalData);

    intervalData.length &&
      intervalData.forEach((time) => {
        if (!timeOutTime) {
          timeOutTime = time;
        }

        if (time < timeOutTime) {
          timeOutTime = time;
        }
      });

    if (timeOutTime) {
      console.log(
        "timeOutTime: ",
        timeOutTime && timeOutTime - compareThisTime + 10000
      );

      timeController = setTimeout(() => {
        rerenderFunction();
      }, timeOutTime - compareThisTime + 10000);
    }
  };

  useEffect(() => {
    return () => {
      timeController && clearTimeout(timeController);
    };
  }, [timeController, rerender]);

  if (!data) {
    return <div>No data...</div>;
  }

  console.log("*****************");
  console.log("** VERSION 1.3 **");
  console.log("*****************");

  return (
    <>
      <HeaderBar type="start" rerenderFunction={rerenderFunction} />
      <BathGrid
        bathCategories={bathCategories}
        business={business}
        userView={userView}
        timeOutController={timeOutController}
      />

      {/* User Views ******/}
      <PoseGroup exitPose={state.type ? "exitFast" : "exit"}>
        {state.showModalView && (
          <PosedDiv key="ModalView">
            <ModalView
              type={state.type}
              bath={state.bath}
              repportValues={state.repportValues}
              intervalData={
                state.intervalData && state.intervalData.maintenance
              }
              status={state.status}
              userView={userView}
            />
          </PosedDiv>
        )}
      </PoseGroup>
      {/* *****************/}

      {loading && (
        <LoadingScreen>
          <span>Loading ...</span>
        </LoadingScreen>
      )}
    </>
  );
};
