import gql from "graphql-tag";

export default gql`
  mutation updateUser($_id: String!, $email: String, $businessID: String, $password: String, $role: String) {
  	updateUser(_id: $_id, email: $email, businessID: $businessID, password: $password, role: $role) {
  		email
  		password
      businessID
      role
  	}
  }
`;
