import React, { useReducer, useContext } from "react";
import { AppContext } from '../../AppContext';
import parseJSON from '../../helpers/parseJSON';
import styled from '@emotion/styled';
import posed, { PoseGroup } from 'react-pose';
import { useMutation } from '@apollo/react-hooks';
import GET_USER_VIEW from '../../graphql/queries/GET_USER_VIEW';
import ADD_REPORT_MAINTENANCE from '../../graphql/mutations/ADD_REPORT_MAINTENANCE';
import UPDATE_BATH from '../../graphql/mutations/UPDATE_BATH';
import Scrollwheel from '../Elements/Scrollwheel';
import ModalReport from '../ViewUser/ModalReport';
import GetReportData from './GetReportData';
import { ReactComponent as IconHistory } from '../../svg/IconHistory.svg';
import { ReactComponent as IconChevron } from '../../svg/IconChevron.svg';
import { ReactComponent as IconCross } from '../../svg/IconCross.svg';
import { ReactComponent as IconErase } from '../../svg/IconErase.svg';

const PosedModalWrapper = styled(posed.div({
  preEnter: {
    y: 400,
  },
  enter: {
    y: 0,
    delay: 200,
    transition: {
      y: { type: 'spring', stiffness: 880, damping: 46 },
      default: { duration: 500 }
    }
  },
  exit: {
    y: 400,
    delay: 200,
    transition: {
      y: { ease: 'easeOut', duration: 600 }
    }
  },
  historyEnter: {
    y: 0,
    delay: 200,
    transition: {
      y: { ease: 'easeOut', duration: 400 }
    }
  },
}))`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
`;

const StyledIconHistory = styled(IconHistory)`
  width: 18px;
  height: 16px;
  margin-left: ${({theme}) => theme.space[1]};
`;

const StyledIconChevron = styled(IconChevron)`
  width: 24px;
  height: 12px;
`;

const BoardWrapper = styled('div')`
  display: flex;
  width: 100%;
  height: calc(100% - ${({theme}) => theme.sizes.headerBarHeight});
  background: ${({theme}) => theme.colors.grey};
`

const Header = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 0 1 30%;
  margin: ${({theme}) => theme.space[2]} ${({theme}) => theme.space[4]} ${({theme}) => theme.space[4]};
`;

const InfoSection = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 100%;

  span {
    font-size: .85rem;
    font-weight: 600;
    text-transform: uppercase;
  }
`;

const Name = styled('h1')`
  text-transform: uppercase;
  margin: 0;
  text-align: center;
  font-size: 2.4rem;
`;

const ShowHistory = styled('span')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 138px;
  height: 42px;
  border: 1px solid ${({theme}) => theme.colors.blueDark};
  border-radius: ${({theme}) => theme.misc.borderRadius};
  font-weight: 600;
  text-transform: uppercase;
`;

const Description = styled('div')`
  flex: 1 1 auto;
  background: ${({theme}) => theme.colors.blueMedium};
`;

const InfoColumn = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 0 1 45%;
`;

const InputHeader = styled('div')`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 1 30%;
  padding: ${({theme}) => theme.space[2]} ${({theme}) => theme.space[4]} ${({theme}) => theme.space[4]};
  background: ${({theme}) => theme.colors.blueCta};

  h2 {
    position: absolute;
    top: 50%;
    transform: translateY(calc(-50% - 36% - 44px));
    margin: 0;
    color: ${({theme}) => theme.colors.white};
    font-size: 2.2rem;
  }
`

const InputColumn = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 0 1 55%;
`;

const InputPanel = styled('div')`
  display: flex;
  flex-wrap: wrap;

  ${({isPanelPresent}) => !isPanelPresent && `
    flex: 1 1 auto;
  `}

  ${({isDisabled}) => isDisabled && `
    span {
      opacity: .2;
      pointer-events: none;
    }
  `}
`;

const InputBoard = styled('div')`
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
  background: ${({theme}) => theme.colors.blueLight};

  ${({isDisabled}) => isDisabled && `
    span {
      opacity: .2;
      pointer-events: none;
    }
  `}
`;

const ActionButton = styled(posed.span({
  pressed: {
    opacity: .6
  },
  notPressed: {
    opacity: 1
  }
}))`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 8%;
  min-height: 72px;
  width: 100%;
  background: ${({theme}) => theme.colors.blueCta};
  color: ${({theme}) => theme.colors.white};
  text-transform: uppercase;
  font-weight: 600;

  ${({theme, registerReport}) => registerReport && `
    background: ${theme.colors.green};
  `}

  span {
    display: flex;
    align-items: center;
  }

  ${({isDisabled}) => isDisabled && `
    opacity: .2 !important;
    pointer-events: none;
  `}
`;

const Cover = styled(posed.div({
  enter: {
    opacity: 1,
    transition: { duration: 100 }
  },
  exit: {
    opacity: 0,
    transition: { duration: 100 }
  },
}))`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1;
`;

const SelectWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: ${({theme}) => theme.space[3]} auto;

  .error {
    padding-top: ${({theme}) => theme.space[3]};
    text-align: center;
    width: 100%;
    font-size: 26px;
    font-weight: 600;
    line-height: 34px;
  }
`;

const Select = styled('span')`
  display: flex;
  justify-content: space-between;
  display: inline-flex;
  align-items: center;
  width: calc(50% - 58px);
  height: 72px;
  padding: 0 ${({theme}) => theme.space[3]};
  background: ${({theme}) => theme.colors.white};
  border-radius: ${({ theme }) => theme.misc.adminInputBorderRadius};
  text-transform: uppercase;
  font-weight: 600;

  ${({isDisabled}) => isDisabled && `
    opacity: .6;
    pointer-events: none;
  `}
`;

const Selector = styled(posed.div({
  enter: {
    y: 0,
    opacity: 1,
    delay: 150,
    transition: {
      y: { type: 'spring', stiffness: 1000, damping: 15 },
      default: { duration: 300 }
    }
  },
  exit: {
    y: 50,
    opacity: 0,
    transition: { duration: 150 }
  },
}))`
  position: absolute;
  top: calc(50% - 150px);
  left: calc(50% - 150px);
  width: 300px;
  height: 300px;
  text-align: center;
  background: ${({theme}) => theme.colors.white};
  z-index: 10;

  h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    background: ${({theme}) => theme.colors.blueDark};
    color: ${({theme}) => theme.colors.white};
    margin: 0;
    padding: 16px 0;
    font-size: 1.1rem;
    text-transform: uppercase;
  }
`;

const Input = styled('span')`
  position: relative;
  display: flex;
  justify-content: space-between;
  display: inline-flex;
  align-items: center;
  width: calc(50% - 58px);
  height: 72px;
  padding: 0 ${({theme}) => theme.space[3]};
  background: ${({theme}) => theme.colors.white};
  border-radius: ${({ theme }) => theme.misc.adminInputBorderRadius};
  text-transform: uppercase;
  font-weight: 600;

  ${({isDisabled}) => isDisabled && `
    opacity: .6;
    pointer-events: none;
  `}
`;

const InputButton = styled(posed.span({
  pressed: {
    background: 'rgba(69, 117, 205, .08)'
  },
  notPressed: {
    background: 'rgba(69, 117, 205, 0)'
  }
}))`
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% / 3 - 1px);
  border: 1px solid ${({theme}) => theme.colors.blue};;
  border-top: none;
  border-left:none;
  font-size: 1.6rem;
  font-weight: 600;
  cursor: pointer;

  &:nth-of-type(3n + 3) {
    border-right: none;
  }
`;

const CrossBadge = styled('div')`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: ${({theme}) => theme.space[2]};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: ${({theme}) => theme.colors.blueDark};

  ${({isDisabled}) => isDisabled && `
    opacity: .2;
    pointer-events: none;
  `}
`;

const StyledIconCross = styled(IconCross)`
  width: 10px;
  height: 10px;
  stroke: ${({theme}) => theme.colors.white};
  stroke-width: 2px;
`;

const StyledIconErase = styled(IconErase)`
  width: 32px;
  height: 24px;
`;

const List = styled('div')`
  display: flex;
  flex-direction: column;
  height: calc(100% - 56px);
  overflow: auto;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    font-size: 1.2rem;
  }
`;

const MaintenanceBoard = ({ bath, repportValues, intervalData, status, userView }) => {
  const coverRef = React.createRef();
  console.log('BATH: ', bath);
  
  const [modalObject, setModalObject] = React.useState({});

  const [specialChar, setSpecialChar] = React.useState(false);

  const [addReportMaintenance] = useMutation(ADD_REPORT_MAINTENANCE);

  const [updateBath] = useMutation(UPDATE_BATH);

    // REUDUCERS
    const initialState = {
      showSelector: false,
      type: '',
      activeAmount: null,
      activeSubstance: null,
    };

    const reducer = (state, action) => {
      switch (action.actionType) {
        case 'SHOW':
          return {
            ...state,
            showSelector: true,
            type: action.type,
          };

        case 'HIDE':
          return {
            ...state,
            showSelector: false,
            type: '',
            activeSubstance: action.substance ? action.substance : state.activeSubstance
          };

        case 'ADD':
          return {
            ...state,
            activeAmount: state.activeAmount ? state.activeAmount + action.amount : action.amount
          };

        case 'ERASE':
          return {
            ...state,
            activeAmount: state.activeAmount.slice(0,-1)
          };

        case 'ERASEALL':
          return {
            ...state,
            activeAmount: null
          };

        default:
          return {
            ...state,
          };
      }
    }

  const [state, selector] = useReducer(reducer, initialState);

  const [buttonPressed, setButtonPressed] = React.useState(false);

  const { appState } = useContext(AppContext);
  const {appData} = appState;

  const maintenanceSubstancesArray = parseJSON(bath.maintenanceSubstances);

  const substanceDataArray = [];

  maintenanceSubstancesArray.forEach(substance => {
    substanceDataArray.push(appData.substances.find(item => item._id === substance).name);
  });

  const inputMeasurementsObject = parseJSON(bath.measurementInputs);
  const repportValuesObject = bath.measurement && parseJSON(bath.measurement.values);

  let errors = null;

  repportValues && repportValues.filter(item => item.status === 'error');
  errors = repportValues ? repportValues.filter(item => item.status === 'error') : repportValuesObject && repportValuesObject.filter(item => item.status === 'error');

  let criticalValues = {};

  const decimalAdjust = (type, value, exp) => {
    // If the exp is undefined or zero...
    if (typeof exp === 'undefined' || +exp === 0) {
      return Math[type](value);
    }
    value = +value;
    exp = +exp;
    // If the value is not a number or the exp is not an integer...
    if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
      return NaN;
    }
    // Shift
    value = value.toString().split('e');
    value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
    // Shift back
    value = value.toString().split('e');
    return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
  }

  const round10 = (value, exp) => decimalAdjust('round', value, exp);

  // 4 = Idealvärde = C
  // 0,67 = Faktor = F
  // item.value = Mätvärde = X
  // 2000 = Volym = V

  // Volym 4500  
  // Faktor 0.25
  // Mätvärde 4
  // Ideal = 3

  // ((3 - (0.25 * 4)) * 4500) / 100 = 90

  if (errors) {
    errors.forEach(item => {
      if (inputMeasurementsObject[item.id].substance) {
        // const calcedValue = (bath.idealValue - (bath.factor * item.value)) * bath.volume / 100; // Magic formula - Pre 2021-02-15
        const calcedValue = ((bath.idealValue - (bath.factor * item.value)) * bath.volume) / 100; // Magic formula - After 2021-02-15
        // const calcedValue2 = ((bath.idealValue - (bath.factor * item.value)) * bath.volume) / 100; // Magic formula safe
        // const calcedValue3 = ((bath.idealValue - (bath.factor * item.value)) / 100) * bath.volume; // Magic formula safe
        const roundValue = round10(calcedValue, -4).toFixed(4);

        // console.log('Värde: ', item.value);
        // console.log('calcedValue: ', calcedValue);
        // console.log('calcedValue2: ', calcedValue2);
        // console.log('calcedValue3: ', calcedValue3);

        criticalValues = {
          substance: inputMeasurementsObject[item.id].substance ? inputMeasurementsObject[item.id].substance : null,
          unit: 'kg',
          value: roundValue
        }
      }
    });
  }

    // console.log('Ideal värde: ', bath.idealValue);
    // console.log('Faktor: ', bath.factor);
    // console.log('Volym: ', bath.volume);
    // console.log('criticalValues?', criticalValues)

  const substanceObject = appData.substances.find(item => item._id === criticalValues.substance);

  const createReport = async () => {
    const now = new Date();
    const nowHours = now.getHours();
    const nowMinutes = now.getMinutes();
    const nowSeconds = now.getSeconds();
    const nowMonth = now.getMonth();
    const nowDate = now.getDate();

    let nowDateString;
    let nowTimeString;
    if (intervalData && intervalData.day && intervalData.time) {
      nowDateString = `${now.getFullYear()}-${nowMonth + 1 < 10 ? '0' : ''}${nowMonth + 1}-${intervalData.day < 10 ? '0' : ''}${intervalData.day}`;
      nowTimeString = intervalData.time;
    } else {
      nowDateString = `${now.getFullYear()}-${nowMonth + 1 < 10 ? '0' : ''}${nowMonth + 1}-${nowDate < 10 ? '0' : ''}${nowDate}`;
      nowTimeString = `${nowHours < 10 ? '0' : ''}${nowHours}:${`${nowMinutes < 10 ? '0' : ''}${nowMinutes}`}:${`${nowSeconds < 10 ? '0' : ''}${nowSeconds}`}`;  
    }

    let values = [];

    values.push(
      {
        value: selectorValueSubstance(),
        status: 'ok',
      },
      {
        value: selectorValueAmount(),
        status: 'ok',
      },
    )

    const stringifiedValues = JSON.stringify(values);

    await addReportMaintenance({
     variables: { bathID: bath._id, date: nowDateString, time: nowTimeString, status: 'ok', values: stringifiedValues },
     refetchQueries: [{ query: GET_USER_VIEW }],
    });
  }

  const deleteForcedMaintenance = async () => {
    await updateBath({
      variables: { _id: bath._id, forcedMaintenance: 'delete' },
     });
  }

  const handleAction = () => {
    setButtonPressed('Register');

    setTimeout(() => {
      setButtonPressed(false);
    }, 100)

    if (intervalData && intervalData.intervalHour === 'forced' && intervalData.intervalMinute === 'forced') {
      deleteForcedMaintenance();
    }

    createReport();

    userView({actionType: 'HIDE_MODAL', type: 'register'});
  }

  const showHistory = name => {
    let modalObject = {}
    modalObject[0] = {
      type: 'history',
      name: name,
    }

    setModalObject(modalObject);
  }

  const selectorAction = (selectorValue, status) => {
    if (status === 'errorCritical' || status === 'errorMaintenance' || status === undefined) return;

    selector({actionType: 'SHOW', type: 'substance'})
  }

  const scrollwheelCallback = (value) => {
    if (state.type === 'substance') {
      selector({actionType: 'HIDE', substance: value, unit:'kg'});
    }
  }

  const selectorValueSubstance = () => {
    if (status === 'errorMaintenance') {
      const substanceObject = appData.substances.find(item => item._id === intervalData.substance);

      return substanceObject.name ? substanceObject.name : 'inget underhållsämne tillgängligt';
    }

    return (status === 'errorCritical' || status === undefined) && substanceObject ? substanceObject.name : state.activeSubstance ? state.activeSubstance : 'Välj ämne';
  }

  const selectorValueAmount = () => {
    if (status === 'errorMaintenance') {
      return intervalData.substanceValue ? intervalData.substanceValue : 'ingen mängd tillgänglig';
    }

    return (status === 'errorCritical' || status === undefined) ? criticalValues.value : state.activeAmount ? state.activeAmount : 'Välj mängd i';
  }

  const addValue = (value, special) => {
    const specialCharFound = /[.]/g

    if (state.activeAmount && state.activeAmount.length > 8) return;
    if ((special && specialChar) || (state.activeAmount && !state.activeAmount.length && special)) return;
    if (value === '.' && state.activeAmount && state.activeAmount.match(specialCharFound) || (value === '.' && !state.activeAmount)) return;

    setButtonPressed(value);

    setTimeout(() => {
      setButtonPressed(false);
    }, 100)

    if (special) {
      setSpecialChar(true);
    }
    selector({actionType: 'ADD', amount: value});
  }

  const erase = () => {
    setButtonPressed('erase');

    setTimeout(() => {
      setButtonPressed(false);
    }, 100)

    if (state.activeAmount && state.activeAmount.length) {
      if (state.activeAmount && state.activeAmount.charAt(state.activeAmount && state.activeAmount.length - 1) === '.') {
        setSpecialChar(false);
      }

      selector({actionType: 'ERASE'});
    }
  }

  const eraseAll = () => {
    setSpecialChar(false);

    selector({actionType: 'ERASEALL'});
  }

  return (
    <BoardWrapper>
      <InfoColumn>
        <Header>
          <InfoSection>
            <span>{bath.category}</span>
            <span>{bath.area}</span>
          </InfoSection>
          <Name>{bath.name}</Name>
          <ShowHistory onClick={() => {showHistory(bath.name)}} >
            Historik <StyledIconHistory />
          </ShowHistory>
        </Header>
        <Description>
        </Description>
      </InfoColumn>
      <InputColumn>
        <InputHeader>
          <h2>Underhåll</h2>
        </InputHeader>

        <InputPanel isPanelPresent={status !== undefined && status !== 'errorCritical' && status !== 'errorMaintenance'} isDisabled={false}>
          <SelectWrapper>
            {(state.activeAmount ||  selectorValueAmount()) && (
              <>
                <Select onClick={() => {selectorAction('substance', status)}}>
                  <span>{selectorValueSubstance()}</span>
                  {(status !== 'errorCritical' && status !== 'errorMaintenance' && status !== undefined) ? (<StyledIconChevron />): null}
                </Select>

                <Input>
                  <span>{selectorValueAmount()} kg</span>
                  {state.activeAmount && (<CrossBadge isDisabled={false} onClick={() => eraseAll()} ><StyledIconCross /></CrossBadge>)}
                </Input>
              </>
            )}

            {(!state.activeAmount &&  !selectorValueAmount()) && (
                <span className="error">Koncentration är för hög. Nollställ bad och kontakta IKP</span>
            )}

          </SelectWrapper>
        </InputPanel>

        { status !== undefined && status !== 'errorCritical' && status !== 'errorMaintenance' && (
          <InputBoard isDisabled={false}>
            <InputButton pose={buttonPressed === '1' ? 'pressed' : 'notPressed'} onClick={() =>{addValue('1')}}>1</InputButton>
            <InputButton pose={buttonPressed === '2' ? 'pressed' : 'notPressed'} onClick={() =>{addValue('2')}}>2</InputButton>
            <InputButton pose={buttonPressed === '3' ? 'pressed' : 'notPressed'} onClick={() =>{addValue('3')}}>3</InputButton>

            <InputButton pose={buttonPressed === '4' ? 'pressed' : 'notPressed'} onClick={() =>{addValue('4')}}>4</InputButton>
            <InputButton pose={buttonPressed === '5' ? 'pressed' : 'notPressed'} onClick={() =>{addValue('5')}}>5</InputButton>
            <InputButton pose={buttonPressed === '6' ? 'pressed' : 'notPressed'} onClick={() =>{addValue('6')}}>6</InputButton>

            <InputButton pose={buttonPressed === '7' ? 'pressed' : 'notPressed'} onClick={() =>{addValue('7')}}>7</InputButton>
            <InputButton pose={buttonPressed === '8' ? 'pressed' : 'notPressed'} onClick={() =>{addValue('8')}}>8</InputButton>
            <InputButton pose={buttonPressed === '9' ? 'pressed' : 'notPressed'} onClick={() =>{addValue('9')}}>9</InputButton>

            <InputButton pose={buttonPressed === '.' ? 'pressed' : 'notPressed'} onClick={() =>{addValue('.', true)}}>.</InputButton>
            <InputButton pose={buttonPressed === '0' ? 'pressed' : 'notPressed'} onClick={() =>{addValue('0')}}>0</InputButton>
            <InputButton pose={buttonPressed === 'erase' ? 'pressed' : 'notPressed'} onClick={() => erase()}><StyledIconErase /></InputButton>
          </InputBoard>
        )}
        {console.log('ACTIVE AMOUNT: ', selectorValueAmount())}
        <ActionButton 
          isDisabled={
            ((selectorValueAmount() === 'Välj mängd i' || !state.activeSubstance) 
            && (status !== undefined && status !== 'errorCritical' 
            && status !== 'errorMaintenance'))
          } 
          pose={buttonPressed === 'Register' ? 'pressed' : 'notPressed'} 
          onClick={() => { 
            handleAction() 
          }}
        >
          {(state.activeAmount ||  selectorValueAmount()) && (
            'Registrera underhåll'
          )}

          {(!state.activeAmount &&  !selectorValueAmount()) && (
            'Nollställ bad'
          )}
        </ActionButton>
      </InputColumn>
      <PoseGroup>
        {state.showSelector && (
          <Cover key='Cover' onClick={() => {selector({actionType: 'HIDE', amount: state.activeAmount})}}/>
        )}
        {state.showSelector && state.type === 'substance' && (
          <Selector key='Selector'>
            <h2>Kemikalie</h2>
            <Scrollwheel currentValue={state.activeSubstance} callback={scrollwheelCallback} values={substanceDataArray} />
          </Selector>
        )}
      </PoseGroup>
      <PoseGroup preEnterPose='preEnter' enterPose={(modalObject[0] && modalObject[0].type === 'history') ? 'historyEnter' : 'enter'} exitPose={(modalObject[0] && modalObject[0].type === 'history') === 'history' ? 'historyExit' : 'exit'}>
        {Object.keys(modalObject).length ? Object.keys(modalObject).reverse().map((id, index) => (
          <PosedModalWrapper key={index}>
            <ModalReport
              key={index}
              name={modalObject[id].name}
              type={modalObject[id].type}
              userView={userView}
              setModalObject={setModalObject}
              coverRef={coverRef}
            >
              <GetReportData bathID={bath._id} offset={0} limit={10} type='maintenance' />
            </ModalReport>
          </PosedModalWrapper>
        )): null}

        {Object.keys(modalObject).length ? (
          <Cover key='Cover' ref={coverRef}/>
        ): null}
      </PoseGroup>
    </BoardWrapper>
  )
}

export default MaintenanceBoard;