import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import styled from '@emotion/styled';
import { CSVLink } from "react-csv";
import ReportDataView from '../ViewUser/ReportDataView';
import GET_BATH_REPORTS from '../../graphql/queries/GET_BATH_REPORTS';
import DELETE_REPORT from '../../graphql/mutations/DELETE_REPORT';
import ADD_REPORT_MEASUREMENT from '../../graphql/mutations/ADD_REPORT_MEASUREMENT';
import ADD_REPORT_MAINTENANCE from '../../graphql/mutations/ADD_REPORT_MAINTENANCE';
import GET_APP_SETTINGS from '../../graphql/queries/GET_APP_SETTINGS';

import { AppContextProvider } from '../../AppContext';
import { AdminButtonAdd } from './AdminButtons';
import Group from './AdminGroup';
import isValidDateFormat from '../../helpers/isValidFormatDate';
import isValidTimeFormat from '../../helpers/isValidFormatTime';
import parseJSON from '../../helpers/parseJSON';
import SubstanceSelect from './SubstanceSelect';
import FormInputWrapper from '../Elements/FormInputWrapper';

const ReportsWrapper = styled('div')`
  margin-bottom: 1rem;
  
  &:last-child {
    margin-bottom: 0;
  }

  ul {
    padding: .5rem 0;
    ${({ theme }) => theme.misc.adminBox}
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }

    li {
      &:first-child {
        padding: .25rem .725rem;
        color: ${({ theme }) => theme.colors.blueDark} !important;
      }
    }
  }
`;

const AddReportsForm = styled('form')`

`;

export default ({ bathID, type, getAdminBathView }) => {
  const limit = 10;
  const [offset, setOffset] = useState(0);
  const [deleteReport] = useMutation(DELETE_REPORT);
  const [addReportMeasurement] = useMutation(ADD_REPORT_MEASUREMENT);
  const [addReportMaintenance] = useMutation(ADD_REPORT_MAINTENANCE);
  const { data: { getAppSettings } = {}, error: appError, loading: appLoading } = useQuery(GET_APP_SETTINGS);
  const { data: { getBathReports } = {}, loading, error, refetch } = useQuery(GET_BATH_REPORTS, { variables: { _id: bathID, offset, limit, type }, fetchPolicy: "network-only" });

  if (loading) return 'Loading'
  if (error) return 'Error'
  if (!bathID) return null
  if (!type) return null;
  if (!getAppSettings) return null;
  if (!getBathReports) return null

  const refetchBathReports = async () => await refetch({ _id: bathID, offset, limit, type });

  const deleteReportByID = async (_id) => {
    await deleteReport({
      variables: { _id, type },
      refetchQueries: [{ query: GET_BATH_REPORTS, variables: { _id: bathID, offset, limit, type }, fetchPolicy: "network-only" }]
    });
  }

  const addReportMaintenanceCallback = async (e, bathData) => {
    e.preventDefault();
    
    const values = [];
    let timeString = null;
    let dateString = null;

    const formInputsTotal = e.target.length - 1;

    for (let i = 0; i < formInputsTotal; i++) {
      const formInput = e.target[i];
      const inputId = formInput.getAttribute('id');
      let value = formInput.value;

      if (inputId === 'date') {
        if (!isValidDateFormat(value)) return false;

        dateString = value;
      }
      
      if (inputId === 'time') {
        if (!isValidTimeFormat(value)) return false;
        
        timeString = value;
      }

      if (inputId === 'substance' || inputId === 'amount') {
        values.push({ value, status: 'ok' })
      }
    }

    const stringifiedValues = JSON.stringify(values);

    await addReportMaintenance({
     variables: { bathID: bathData._id, date: dateString, time: timeString, status: 'ok', values: stringifiedValues },
     refetchQueries: [{ query: GET_BATH_REPORTS, variables: { _id: bathID, offset, limit, type }, fetchPolicy: "network-only" }]
    });
  }

  const addReportMeasurementCallback = async (e, bathData) => {
    e.preventDefault();

    if (!bathData && !bathData.measurementInputs) return null;
    let bathMeasurementData = null

    bathMeasurementData = parseJSON(bathData.measurementInputs)

    // Make sure this code works the same as in MeasureBoard.js
    const values = [];
    let timeString = null;
    let dateString = null;
    let reportStatus = 'ok'; 

    const formInputsTotal = e.target.length - 1;

    for (let i = 0; i < formInputsTotal; i++) {
      const formInput = e.target[i];
      const inputId = formInput.getAttribute('id');
      let measurementValue = formInput.value;
      let status = 'ok';

      if (inputId === 'date') {
        if (!isValidDateFormat(measurementValue)) return false;

        dateString = measurementValue;
      }

      if (inputId === 'time') {
        if (!isValidTimeFormat(measurementValue)) return false;

        timeString = measurementValue;
      }

      if (measurementValue) {
        const measurementId = formInput.getAttribute('data-measurement-id');
      
        if (bathMeasurementData[measurementId] && bathMeasurementData[measurementId].inputMin && bathMeasurementData[measurementId].inputMax) {
          // measurementValue = handleConcentrationValue(measurementValue, measurementId, bath.factor);

          const inputMin = Number(bathMeasurementData[measurementId].inputMin);
          const inputMax = Number(bathMeasurementData[measurementId].inputMax);

          if (measurementValue < inputMin || measurementValue > inputMax) {
            if (measurementValue < inputMin) {
              status = 'error';
              reportStatus = bathMeasurementData[measurementId].substance ? 'errorCritical' : 'error';              
            } else if (measurementValue > inputMax) {
              status = 'ok'
            }
          } else {
            status = 'ok';
          }
        }

        if (inputId !== 'date' && inputId !== 'time') {
          values.push({id: measurementId, value: measurementValue, status })
        }
      }
    }

    if (values.length > 0) {
      const stringifiedValues = JSON.stringify(values);

      await addReportMeasurement({
        variables: { bathID, date: dateString, time: timeString, status: reportStatus, values: stringifiedValues },
        refetchQueries: [{ query: GET_BATH_REPORTS, variables: { _id: bathID, offset, limit, type }, fetchPolicy: "network-only" }]
      });
    }

  };

  const bathReports = getBathReports[type];
  const reports = bathReports.sort((a, b) => new Date(`${b.date} ${b.time}`) - new Date(`${a.date} ${a.time}`));
  const total = getBathReports.total || 0;

  const csvHeaders = [
    { label: "date", key: "date" },
    { label: "time", key: "time" },
    { label: "status", key: "status" },
    { label: "values", key: "values" }
  ];

  const csvData = reports.map(h => {
    return { date: h.date, time: h.time, status: h.status, values: h.values }
  });

  // const deleteMeasurementByID = async (_id, status) => {
  //   const type = status ? 'measurement' : 'maintenance';
  //   const res = await deleteReport({
  //     variables: { _id, type },
  //     refetchQueries: [{ query: GET_BATH_REPORTS, variables: { _id: bathID, offset, limit }, fetchPolicy: "network-only" }]
  //   });
  // }

  const reportsNext = () => setOffset(o => o + limit);
  const reportsPrevious = () => setOffset(o => o - limit);

  const bathReportViewObject = {[type]: reports};

  const { measurementInputs, bath, substances } = getAdminBathView;

  const maintenanceSubstances = parseJSON(bath.maintenanceSubstances)
  const maybeSubstancesOptions = Array.isArray(maintenanceSubstances) && substances.filter(substance => maintenanceSubstances.indexOf(substance._id) !== -1);

  return (
    <Group>
      <ReportsWrapper>
        <h3>{type === 'measurement' ? 'Mätningar' : 'Underhåll'}</h3>
        <CSVLink data={csvData} headers={csvHeaders}>Ladda ner csv</CSVLink>
        <Group>
        {type === 'maintenance' && (
            <>
            <h4>Lägg till underhållsrapport</h4>
            <AddReportsForm onSubmit={(e) => addReportMaintenanceCallback(e, bath)}>
              <FormInputWrapper>
                <div>
                  <label htmlFor={'date'}>Datum</label>
                  <input
                    type="text" 
                    id={'date'}
                    placeholder={'ÅÅÅÅ-MM-DD'}
                    required
                  />
                </div>
                <div>
                  <label htmlFor={'time'}>Tid</label>
                  <input
                    type="text" 
                    id={'time'}
                    placeholder={'TT:MM:SS'}
                    required
                  />
                </div>
                <div>
                  <SubstanceSelect substances={maybeSubstancesOptions} htmlFor={'substance'} />
                </div>
                <div>
                  <label htmlFor={'amount'}>Mängd</label>
                  <input
                    type="text" 
                    id={'amount'}
                    required
                  />
                </div>
              </FormInputWrapper>
              <AdminButtonAdd type="submit">Skapa rapport</AdminButtonAdd>
            </AddReportsForm>
            </>
          )}
          {type === 'measurement' && (
            <>
            <h4>Lägg till mätningsrapport</h4>
            <AddReportsForm onSubmit={(e) => addReportMeasurementCallback(e, bath)}>
                {measurementInputs && measurementInputs.length > 0 && (
                  <FormInputWrapper>
                    <div>
                      <label htmlFor={'date'}>Datum</label>
                      <input
                        type="text" 
                        id={'date'}
                        placeholder={'ÅÅÅÅ-MM-DD'}
                        required
                      />
                    </div>
                    <div>
                      <label htmlFor={'time'}>Tid</label>
                      <input
                        type="text" 
                        id={'time'}
                        placeholder={'TT:MM:SS'}
                        required
                      />
                    </div>
                    {measurementInputs.map(measurementInput => (
                      <div key={measurementInput._id}>
                        <label htmlFor={measurementInput._id}>{measurementInput.adminName}</label>
                        <input
                          type="text" 
                          id={measurementInput._id}
                          required={
                            measurementInput.name === 'koncentration' || 
                            measurementInput.name === 'temperatur'
                          }
                          data-measurement-name={measurementInput.name} 
                          data-measurement-id={measurementInput._id} 
                        />
                      </div>
                    ))}
                  </FormInputWrapper>
                )}
              <AdminButtonAdd type="submit">Skapa rapport</AdminButtonAdd>
            </AddReportsForm>
            </>
          )}
        </Group>
        {reports && (
          <>
            <AppContextProvider appData={getAppSettings}>
              <ReportDataView admin bath={bath} substances={substances} deleteReportByID={deleteReportByID} type={type} bathReports={bathReportViewObject} refetchBathReports={refetchBathReports} />
            </AppContextProvider>
          </>
        )}
        <button onClick={reportsPrevious} disabled={limit > offset}>Föregående</button>
        <button onClick={reportsNext} disabled={offset >= (total - limit)}>Nästa</button>
      </ReportsWrapper>
    </Group>
  );
}
