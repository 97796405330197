import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { User } from "./pages/User";

export default () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="*" component={User} />
      </Switch>
    </BrowserRouter>
  )
}
