import React, { useState } from 'react';
import styled from '@emotion/styled';

const SingleSlot = styled('div')`
  select, input {
    display: block;
    margin-bottom: .5rem;
  }

  input[name="substanceValue"] {
    max-width: 80px;
  }
`;

const DayLabel = styled('label')`
  margin-right: 1rem;
`;

const TimeContainer = styled('div')`
  display: flex;

  > label:first-of-type {
    margin-right: .5rem;
  }
`;

const Hours = () => {
  const hours = [];
  for (let i = 0; i < 23; i++) {
    // if (i !== 24) {
      hours.push(<option key={i} value={i}>{i}</option>);
    // }
  }

  return hours;
}

const Minutes = () => {
  const minutes = [];
  // for (let i = 0; i < 5; i++) {
  //   const mi = i * 15;
  for (let i = 0; i < 59; i++) {
    // if (mi !== 60) {
      minutes.push(<option key={i} value={i}>{i === 0 ? '00' : i}</option>);
    // }
  }

  return minutes;
}

export default ({ substances, type, handleChange, initialState, day, slotIndex, deleteSlot, slotData }) => {
  const title = type === 'measurement' ? 'Mätning' : 'Underhåll'
  const intervalMinute = slotData.intervalMinute ? slotData.intervalMinute : '0';
  const intervalHour = slotData.intervalHour ? slotData.intervalHour : '0';

  // const checkDay = dayNr => {
  //   if (initialState.interval
  //     && initialState.interval[type]
  //     && initialState.interval[type].days
  //     && initialState.interval[type].days.length > 0) {
  //       return initialState.interval[type].days.indexOf(dayNr) !== -1;
  //   }
  // }

  // console.log('slotData?', slotData);

  return (
    <SingleSlot>
      {type === 'maintenance' && (
        <>
        {substances && (
          <label>Ämne
            <select key={'substance'+type+day} name={'substance'} onChange={e => handleChange(e, day, slotIndex)} defaultValue={slotData.substance || null}>
              <option value="">Ingen vald</option>
              {substances.map(s => <option key={s._id} value={s._id}>{s.name}</option> )}
            </select>
          </label>
        )}
        <div>
          <label>Mängd
            <input type="text" name={'substanceValue'} value={slotData.substanceValue} onChange={e => handleChange(e, day, slotIndex)} />
          </label>
        </div>
      </>
      )}

      <TimeContainer>
        <label>
          Timma
          <select key={`hour${type}${intervalHour}`} onChange={e => handleChange(e, day, slotIndex)} name={"intervalHour"} defaultValue={intervalHour}>
            <Hours />
          </select>
        </label>

        <label>
          Minut
          <select key={`minute${type}${intervalMinute}`} onChange={e => handleChange(e, day, slotIndex)} name={"intervalMinute"} defaultValue={intervalMinute}>
            <Minutes />
          </select>
        </label>
      </TimeContainer>
      <button onClick={e => deleteSlot(e, day, slotIndex)}>Ta bort</button>
    </SingleSlot>
  )
}
