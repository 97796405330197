import React, { useState } from 'react';
import { AdminButtonUpdate } from '../../components/Admin/AdminButtons';

const UserHandler = ({ getAdminUserView, updateUser, userID }) => {
  const { email, role } = getAdminUserView.user;
  const businessID = getAdminUserView.business && getAdminUserView.business._id || '';
  const { businesses } = getAdminUserView;

  console.log('getAdminUserView?', getAdminUserView);

  const [initialState, setInitialState] = useState({
    email,
    businessID,
    role,
    password: '',
  });

  const handleChange = (e) => {
    const inputTarget = e.target;
    const inputValue = e.target.value;
    const inputName = e.target.name;

    setInitialState(is => {
      is[inputName] = inputValue;
      return {
        ...is,
      }
    });
  }

  return (
    <>
      <h1>{email}</h1>
      <form
        onSubmit={async e => {
          e.preventDefault();

          const response = await updateUser({
            variables: {
              _id: userID,
              email: initialState.email,
              businessID: initialState.businessID,
              role: initialState.role,
              password: initialState.password
            }
          });
        }}
      >
      <div>
        <input
          value={initialState.email || ''}
          placeholder="email"
          name="email"
          onChange={handleChange}
        />
      </div>
      <div>
        <input
          type="password"
          name="password"
          value={initialState.password || ''}
          placeholder="Nytt lösenord"
          onChange={handleChange}
        />
      </div>
      <div>
      <select key={initialState.role} onChange={handleChange} name="role" defaultValue={initialState.role}>
        <option value="">Ingen vald</option>
        <option value="user">Kund</option>
        <option value="admin">Administratör</option>
      </select>
      </div>
        {businesses && businesses.length > 0 && (
          <div>
            <label>Företag
              <select name="businessID" onChange={handleChange} defaultValue={initialState.businessID || null}>
              <option value="">Ingen vald</option>
                {businesses.map(b => <option value={b._id} key={b._id}>{b.name}</option>)}
              </select>
            </label>
          </div>
        )}
        <AdminButtonUpdate type="submit">Uppdatera användare</AdminButtonUpdate>
      </form>
    </>
  )
};

export default UserHandler;
